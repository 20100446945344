import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import utils from "../../../utils/web-utils";
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import soundUtil from "../../OtherComponents/SoundPlayer/soundUtil";
import {setSoundToPlay} from "../../OtherComponents/SoundPlayer/SoundPlayerSlice";
import {useDispatch, useSelector} from "react-redux";
import {selectListenToWord} from "../../OtherComponents/NavBar/SettingsDrawerSlice";
import Utils from "../../../utils/web-utils";


const useStyles = makeStyles(() => ({
    icon: {
        fontSize: "30px"
    },
}));

export default (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const listenToWord = useSelector(selectListenToWord);

    const handlePlaySound = (wordToPlay) => {
        const soundToPlay = props.fetchedList.listenToWordList[wordToPlay];
        Utils.sendGAEvent("WordListViewer", "PlaySound", props.fetchedList.code)
        dispatch(setSoundToPlay({newState: soundUtil.getSoundUrlForWord(soundToPlay)}));
    }

    const wordToPlay = props.currentWord && props.currentWord.word ? props.currentWord.word : props.currentWord;
    const hasSoundToPlay = props.fetchedList && props.fetchedList.listenToWordList && props.fetchedList.listenToWordList[wordToPlay]
    const showPlaySoundButton = listenToWord && props.startedReading && props.fetchedList && props.fetchedList.listenToWord;

    return (
        <>
            {showPlaySoundButton && hasSoundToPlay &&
            <Fab color="primary" {...utils.stopEventPropigation} onClick={() => handlePlaySound(wordToPlay)} aria-label="add">
                <VolumeUpIcon className={classes.icon}/>
            </Fab>
            }
        </>
    );
}
