import React from 'react';
import Chip from "@material-ui/core/Chip";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    chip: {
        margin: 1,
    },
}));

export default (props) => {
    const classes = useStyles();

    let chipList = [];
    if (props.list) {
        chipList = props.list.map((item, i) => <Chip key={i} size="small" label={item} className={classes.chip}/>);
    }
    return (
        <>{chipList}</>
    )
}
