import {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {setWidth, setHeight, setFullscreen} from "./WithAndHeightSlice";

export default () => {
    const dispatch = useDispatch();
    useEffect(() => {
        setWindowWidth();
        window.addEventListener('resize', () => setWindowWidth());
        return () => window.removeEventListener('resize', () => setWindowWidth());
    }, []) // eslint-disable-line

    function setWindowWidth() {
        const {innerWidth: width, innerHeight: height} = window;

        dispatch(setFullscreen({newState: (window.innerWidth === window.screen.width && height === window.screen.height)}));


        dispatch(setWidth({newState: width}));
        dispatch(setHeight({newState: height}));
    }

    return null;
}
