import React, {useEffect, useState} from 'react';
import ListItem from "@material-ui/core/ListItem";
import Chip from "@material-ui/core/Chip";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import utils from "../../../utils/web-utils";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
    title: {
        marginLeft: "18px"
    },
    inputField: {
        margin: (theme.spacing(2), theme.spacing(1)),
        flex: 1,
    },
    chip: {
        margin: (theme.spacing(1), theme.spacing(2)),
    },
    chipList: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(20),
            height: theme.spacing(20),
        },
    },
    textField: {
        width: "100%"
    },
    image: {
        marginLeft: "20px",
    }
}));

const ListDetailsEditableChip = (props) => {
    const classes = useStyles();

    function handleChange(e) {
        const {value} = e.target;

        props.handleChange(props.index, value)
    }

    return (
        <Paper elevation={3}>
            <img className={classes.image} height={"70%"} src={props.image} alt={"Mynd af " + props.word}/>

            <TextField
                className={classes.inputField}
                value={props.word}
                type="text"
                onChange={handleChange}
                // name={props.name}
            />
        </Paper>
    );
}

export default (props) => {
    const classes = useStyles();

    function handleChange(i, newValue) {
        const list = props.listDetails[props.name];
        list[i].word = newValue;
        props.handleChange({target: {value: list, name: props.name}})
    }

    let chipList = [];
    if (props.listDetails[props.name]) {
        chipList = props.listDetails[props.name].map((item, i) =>
            <ListDetailsEditableChip key={i} index={i} image={item.image} word={item.word} handleChange={handleChange}/>
        )
    }
    return (
        <>
            <h3 className={classes.title}>{props.title} ({chipList ? chipList.length : ""})</h3>
            <div className={classes.chipList}>
                {chipList}
            </div>
        </>
    );
}