import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from '@material-ui/icons/Add';
import {makeStyles} from "@material-ui/core/styles";
import {openCloseNewCategoryDialog} from '../ListManager/ListManagerSlice';
import {useDispatch} from "react-redux";
import NewCategoryDialog from "./NewCategoryDialog";


const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

const useStyles = makeStyles(() => ({
    icon: {
        padding: 10,
    }
}));

export default function CheckboxesTags(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    let selectedItems = [];
    if (props.listDetails[props.name]) {
        selectedItems = props.allCheckboxItems.filter(category => props.listDetails[props.name].includes(category.title));
    }

    function addNewItemToList(value) {
        let selectedItems = props.listDetails[props.name] ? props.listDetails[props.name] : []
        selectedItems.push(value)
        props.handleChange({target: {value: selectedItems, name: props.name}})
    }

    function handleChange(e, value) {
        props.handleChange({target: {value: value.map(item => item.title), name: props.name}})
    }

    return (
        <>
            <NewCategoryDialog addNewItemToList={addNewItemToList}/>
            <ListItem>
                <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    onChange={handleChange}
                    options={props.allCheckboxItems}
                    disableCloseOnSelect
                    value={selectedItems}
                    getOptionLabel={(option) => option.title}
                    renderOption={(option, {selected}) => (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{marginRight: 8}}
                                checked={selected}
                            />
                            {option.title}
                        </React.Fragment>
                    )}
                    style={{width: 500}}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" label={props.title} placeholder=""/>
                    )}
                />
                <IconButton onClick={() => dispatch(openCloseNewCategoryDialog({code: true}))} className={classes.icon} aria-label="icon">
                    <AddIcon/>
                </IconButton>
            </ListItem>
        </>
    );
}