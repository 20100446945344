import {Textfit} from 'react-textfit';
import React from "react";
import {selectWidth} from "../../../utils/WithAndHeightUpdater/WithAndHeightSlice";
import {useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {selectAllCaps} from "../../OtherComponents/NavBar/SettingsDrawerSlice";

export default (props) => {
    const useStyles = makeStyles((theme) => ({
        centeredText: {
            textAlign: "center",
            top: "30vh",
            position: "absolute",
            width: "100%",
            display: "table-cell"
        }
    }))

    const windowWidth = useSelector(selectWidth);
    const classes = useStyles();

    const allCaps = useSelector(selectAllCaps);
    const word = allCaps && props.word ? props.word.toUpperCase() : props.word;

    return (
        <div className={'no-select ' + classes.centeredText}>
            <Textfit
                mode="single"
                max={windowWidth * 0.15}>
                {word}
            </Textfit>
        </div>
    );
}
