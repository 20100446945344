import {createSlice} from '@reduxjs/toolkit';

export const slice = createSlice({
    name: 'toast',
    initialState: {
        toastOpen: false,
        message: "",
        severity: "info"
    },
    reducers: {
        setToastOpen: (state, action) => {
            state.toastOpen = action.payload.newState;
            state.message = action.payload.message;
            state.severity = action.payload.severity;
        },
    },
});


export const selectOpenState = state => state.toast.toastOpen;
export const selectMessage = state => state.toast.message;
export const selectSeverity = state => state.toast.severity;
export const {setToastOpen} = slice.actions;

export default slice.reducer;