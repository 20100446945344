import CookieConsent from "react-cookie-consent";


import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Link from "../CustomLink/CustomLink";

const useStyles = makeStyles((theme) => ({
    link: {
        color: "white",
    }
}));

const SETTINGS_VERSION = "1.0"
const COOKIE_KEY = "cookieAccept"

export default (props) => {
    const [showPopUp, setShowPopUp] = useState(true);
    const classes = useStyles();

    useEffect(() => {
        if (typeof localStorage !== "undefined") {
            try {
                const key = COOKIE_KEY + SETTINGS_VERSION;
                setShowPopUp(localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : true);
            } catch (e) {
                saveAndToLocalStorage(true)
                setShowPopUp(true)
            }
        }
    }, [])  // eslint-disable-line


    function saveAndToLocalStorage(newState) {
        localStorage.setItem(COOKIE_KEY + SETTINGS_VERSION, JSON.stringify(newState));
        setShowPopUp(newState)
    }

    return (
        <>
            {showPopUp && typeof localStorage !== "undefined" &&
            <CookieConsent
                style={{
                    height: "55px",
                    zIndex: 1001
                }}
                buttonStyle={{
                    borderRadius: "100",
                    backgroundColor: "white"
                }}
                innerStyle={{
                    height: "55px"
                }}
                acceptOnScroll={true}
                acceptOnScrollPercentage={50}
                buttonText="Loka"
                buttonClasses="btn btn-primary"
                containerClasses="alert alert-warning col-lg-12"
                onAccept={() => saveAndToLocalStorage(false)}
            >
                Velkomin á 100 orð. Þessi vefur notar vefkökur. <Link to={"/vefkokur"} isExported={props.isExported} className={classes.link} color="inherit">Sjá nánar.</Link>
            </CookieConsent>
            }
        </>
    );
}