import {createSlice} from '@reduxjs/toolkit';

export const slice = createSlice({
    name: 'widthAndHeightSlice',
    initialState: {
        width: 1337,
        height: 1337,
        fullscreen: false
    },
    reducers: {
        setWidth: (state, action) => {
            state.width = action.payload.newState;
        },
        setHeight: (state, action) => {
            state.height = action.payload.newState;
        },
        setFullscreen: (state, action) => {
            state.fullscreen = action.payload.newState;
        },
    },
});

export const selectWidth = state => state.widthAndHeight.width;
export const selectHeight = state => state.widthAndHeight.height;
export const selectMinWh = state => Math.min(state.widthAndHeight.width, state.widthAndHeight.height);
export const selectFullscreen = state => state.widthAndHeight.fullscreen;
export const {setHeight, setWidth, setFullscreen} = slice.actions;

export default slice.reducer;