import {createSlice} from '@reduxjs/toolkit';

export const slice = createSlice({
    name: 'frontPage',
    initialState: {
        searchString: "",
    },
    reducers: {
        setSearchString: (state, action) => {
            state.searchString = action.payload.newState;
        },
    },
});

export const selectSearchString = state => state.frontPage.searchString;
export const {setSearchString} = slice.actions;

export default slice.reducer;