import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Footer from "../../OtherComponents/Footer/Footer";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import ReactMarkdown from "react-markdown";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: "calc(100vh - 56px - 64px - 32px)",
    },
    main: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(2),
    },
    footer: {
        padding: theme.spacing(3, 2),
        marginTop: 'auto',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    },
}));

export default () => {


    const text =
`Listarnir eru flokkaðir eftir tegund, t.d. eftir því hvort um er að ræða orðalista úr bók, eftir tíðni eða orðflokkum. Ef ýtt er á örina niður á hverju spjaldi má sjá ítarupplýsingar fyrir hvern orðalista. Þegar orðalisti hefur verið valinn birtist eitt orð í einu og notandi les orðið, upphátt eða í hljóði. Eftir að orð hefur verið lesið er ýtt á bilstöng, örvarnar eða smellt á skjáinn til að fá næsta orð á skjáinn. Við lok lesturs fær notandi upplýsingar um hversu langan tíma lesturinn tók.

Þegar ýtt er á tannhjólið uppi í hægra horninu birtast ýmsir stillingarvalmöguleikar. Þar er m.a. hægt að velja orðafjölda hverrar atrennu, frá 10 orðum upp í 100. Einnig er hægt að velja hvort orðin birtist í handahófskenndri röð eða eftir tíðni þar sem fyrsta orðið er algengasta orðið, annað orðið er næst algengast o.s.frv.

Þá er hægt að velja að fá auðan skjá á milli þess sem orð birtast. Þetta getur t.d. verið gagnlegt fyrir kennara og/eða forráðamenn sem vilja stjórna hversu lengi viðkomandi fær að sjá orðið sem um ræðir. 

Útlitsstillingar: Hægt er að breyta útliti þannig að bakgrunnur verði svartur og orðin hvít. Eins er hægt að velja sérstakt letur, svonefnt OpenDyslexic letur, en rannsóknir hafa sýnt að það geti reynst einstaklingum með lesblindu/dyslexíu vel. Von bráðar verður einnig möguleiki á að velja aðra liti en svartan og hvítan fyrir bakgrunn og stafi.`


    const classes = useStyles();
    return (
        <>
            <div className={classes.root}>
                <CssBaseline/>
                <Container component="main" className={classes.main} maxWidth="md">
                    <Typography variant="h2" component="h1" gutterBottom>
                        Leiðbeiningar
                    </Typography>
                    <Typography variant="subtitle1" component="p" gutterBottom>
                        <ReactMarkdown source={text}/>
                    </Typography>
                </Container>
            </div>
            <Footer className={classes.footer}/>

        </>
    );
}
