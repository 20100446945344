import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import apiWrapper from "../../../utils/apiWrapper";
import SimpleLoading from "../../OtherComponents/Loading/SimpleLoading";
import {useDispatch, useSelector} from "react-redux";
import SingleWord from "./SingleWord";
import ReadingFinished from "./ReadingFinished";
import {selectRandomize, selectEmptyBetween, selectWordListLength, selectBackgroundColor, selectFontFamily, initSettings, selectAllCaps} from "../../OtherComponents/NavBar/SettingsDrawerSlice";
import utils from "../../../utils/web-utils";
import KeyboardEventHandler from "./KeyboardEventHandler";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import {useHistory} from "react-router-dom";
import MiniMenu from "./MiniMenu";
import WordWithPicture from "./WordWithPicture";
import useLongPress from "../../../utils/useLongPress";
import Utils from "../../../utils/web-utils";
import {selectCardIndex, selectShowEmptyNow, selectStartedReading, setCardIndex, setShowEmptyNow, setStartedReading} from "./WordListViewerSlice";
import WordDescWithPicture from "./WordDescWithPicture";
import LeftMiniMenu from "./LeftMiniMenu";


const useStyles = makeStyles((theme) => ({
    leftSideTitle: {
        textAlign: "center",
        position: "absolute",
        top: 3,
        left: 10,
        display: "table-cell",
        ['@media (min-width:900px)']: { // eslint-disable-line
            left: 30,
            top: 15,
        },
    },
    rightSideMenu: {
        index: 999,
        position: 'absolute',
        bottom: theme.spacing(9),
        right: theme.spacing(2),
        ['@media (min-width:666px)']: { // eslint-disable-line
            bottom: theme.spacing(12),
            right: theme.spacing(5),
        },
        ['@media (min-width:1480px)']: { // eslint-disable-line
            bottom: theme.spacing(18),
            right: theme.spacing(9),
        },
    },
    leftSideMenu: {
        index: 999,
        position: 'absolute',
        bottom: theme.spacing(9),
        left: theme.spacing(2),
        ['@media (min-width:666px)']: { // eslint-disable-line
            bottom: theme.spacing(12),
            left: theme.spacing(5),
        },
        ['@media (min-width:1480px)']: { // eslint-disable-line
            bottom: theme.spacing(18),
            left: theme.spacing(9),
        },
    },
    wordCounter: {
        height: "100px",
        position: 'absolute',
        bottom: theme.spacing(9),
        right: "calc(50vw - 100px)",
        width: "200px",
        textAlign: "center",
        fontWeight: "600",
        top: 0,
        ['@media (max-width:530px)']: { // eslint-disable-line
            top: "-12px"
        },
    },
    mainEventDiv: {
        height: "calc(100vh - 64px)",
    },
    mainWindow: {
        position: "fixed",
        width: "100%",
        height: "100%"
    },
    arrowBack: {
        marginTop: -3,
        marginRight: 5,
    },
    centeredText: {
        textAlign: "center",
        top: "30vh",
        position: "absolute",
        width: "100%",
        display: "table-cell",
    }
}));

export default (props) => {
    const dispatch = useDispatch();

    const [fetchedList, setFetchedList] = useState(null);
    const [listBeingRead, setListBeingRead] = useState([]);

    const startedReading = useSelector(selectStartedReading);
    const cardIndex = useSelector(selectCardIndex);
    const showEmptyNow = useSelector(selectShowEmptyNow);

    const [startTime, setStartTime] = useState(Date.now());
    const [elapsedTime, setElapsedTime] = useState(Date.now() - startTime);
    const randomizeList = useSelector(selectRandomize);
    const allCaps = useSelector(selectAllCaps);
    const emptyCardBetween = useSelector(selectEmptyBetween);
    const wordListLength = useSelector(selectWordListLength);
    const backgroundColor = useSelector(selectBackgroundColor);
    const fontFamily = useSelector(selectFontFamily);

    const backspaceLongPress = useLongPress((step) => updateCardIndex(step), 500);

    //TODO find out how to do fullscreen pseudo css class
    let fullscreenBgClass = backgroundColor ? " blackFullscreen" : "";
    let iconColor = backgroundColor ? {color: "white"} : {};
    let customStyle = backgroundColor ? {
        backgroundColor: "black",
        color: "#F2F2F2",
    } : {}
    customStyle.fontFamily = fontFamily;

    const classes = useStyles();
    const history = useHistory();
    const {params} = props.match;

    let top = <></>
    let card = <SimpleLoading/>
    let infoCard = <></>
    let wordCounter = <></>

    useEffect(() => {
        dispatch(setStartedReading({newState: false}));
        dispatch(setCardIndex({newState: 0}));

        fetchData().catch(console.error);
    }, []); // eslint-disable-line

    async function fetchData() {
        const result = await apiWrapper.getWordList(params.code)
        if (result.published === false) {
            history.push("/404")
            return
        }

        dispatch(initSettings({newState: params.code, settings: result.settings}));

        setFetchedList(result)
        setListBeingRead(result.list)
        if (result.type === "WordsAndPictures" || result.type === "WordsDescAndPictures") {
            //Cache all the images
            for (const item of result.list) {
                const tmpImage = new Image();
                tmpImage.src = item.image
            }
        }
    }

    function readAgain() {
        dispatch(setStartedReading({newState: false}));
        dispatch(setCardIndex({newState: 0}));
        dispatch(setShowEmptyNow({newState: 0}));
        Utils.sendGAEvent("WordListViewer", "ReadAgain", fetchedList.code, listBeingRead ? listBeingRead.length : 0)
    }

    function updateCardIndex(step) {
        if (!startedReading && fetchedList) {
            let theList = JSON.parse(JSON.stringify(fetchedList.list));

            if (randomizeList) {
                theList = utils.randomizeArray(theList);
            }

            setListBeingRead(utils.setWordListLength(theList, wordListLength));
            dispatch(setStartedReading({newState: true}));

            setStartTime(Date.now())
            Utils.sendGAEvent("WordListViewer", "StartReading", fetchedList.code, listBeingRead ? listBeingRead.length : 0)
            return
        }

        if (listBeingRead.length === cardIndex + step) {
            setElapsedTime(Date.now() - startTime)
            Utils.sendGAEvent("WordListViewer", "FinishedReading", fetchedList.code, listBeingRead ? listBeingRead.length : 0)
        }

        if (listBeingRead && cardIndex + step >= 0 && listBeingRead.length > cardIndex) {
            if (showEmptyNow !== 0) {
                dispatch(setCardIndex({newState: cardIndex + (showEmptyNow + step) / 2}));
                dispatch(setShowEmptyNow({newState: 0}));
            } else if (emptyCardBetween) {
                dispatch(setShowEmptyNow({newState: step}));
            } else {
                dispatch(setCardIndex({newState: cardIndex + step}));
            }
        }
    }

    if (fetchedList) {
        top = fetchedList.name
        const startReading = "Smelltu til að byrja að lesa";
        infoCard = <h1> {allCaps ? startReading.toUpperCase() : startReading} </h1>
        card = <></>
        if (startedReading) {
            top = fetchedList.name
            if (listBeingRead.length > cardIndex) {
                wordCounter = <p>{cardIndex + 1} af {listBeingRead.length}</p>
                if (showEmptyNow !== 0) {
                    card = <></>
                    infoCard = <></>
                } else {
                    if (fetchedList.type === "WordsAndPictures") {
                        card = <WordWithPicture item={listBeingRead[cardIndex]}/>
                    } else if (fetchedList.type === "WordsDescAndPictures") {
                        card = <WordDescWithPicture item={listBeingRead[cardIndex]}/>
                    } else {
                        card = <SingleWord word={listBeingRead[cardIndex]}/>
                    }
                    infoCard = <></>
                }
            } else {
                wordCounter = <></>
                infoCard = <ReadingFinished code={fetchedList.code} elapsedTime={elapsedTime} readAgain={readAgain}/>
                card = <></>
            }
        }
    }

    return (
        <>
            <KeyboardEventHandler updateCardIndex={updateCardIndex}/>
            <div style={customStyle}>
                <div className={classes.mainEventDiv}>
                    <div id="mainWindow" {...backspaceLongPress} className={`${classes.mainWindow} ${fullscreenBgClass}`}>
                        <div className={`${classes.centeredText} no-select`}>
                            {infoCard}
                        </div>
                        <div>
                            {card}
                        </div>
                        <div className={`${classes.wordCounter} no-select`}>
                            {wordCounter}
                        </div>
                        <div {...utils.stopEventPropigation} className={`${classes.leftSideTitle} no-select`}>
                            <h2>
                                <IconButton style={iconColor} className={classes.arrowBack} onClick={() => history.push("/")} aria-label="icon">
                                    <ArrowBackIosIcon className={classes.icon}/>
                                </IconButton>
                                <span>{top}</span>
                            </h2>
                        </div>
                        <div className={`${classes.rightSideMenu} no-select`}>
                            <MiniMenu/>
                        </div>
                        <div className={`${classes.leftSideMenu} no-select`}>
                            <LeftMiniMenu startedReading={startedReading} fetchedList={fetchedList} currentWord={listBeingRead[cardIndex]}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
