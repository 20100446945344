import React from 'react';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Select from "@material-ui/core/Select";
import {selectWordListLength, setWordListLength} from "../SettingsDrawerSlice";
import MenuItem from "@material-ui/core/MenuItem";
import {useDispatch, useSelector} from "react-redux";

export default (props) => {
    const wordListLength = useSelector(selectWordListLength);
    return (
        <>
            Fjöldi orða í lista
            <ListItemSecondaryAction>
                <Select
                    style={props.style}
                    value={wordListLength}
                    onChange={(e) => props.listLengthChange(e)}
                >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                </Select>
            </ListItemSecondaryAction>
        </>
    )
}
