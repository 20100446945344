import React, {useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Footer from "../../../OtherComponents/Footer/Footer";
import DoneIcon from '@material-ui/icons/Done';
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import BackspaceIcon from '@material-ui/icons/Backspace';
import IconButton from "@material-ui/core/IconButton";
import ReplayIcon from "@material-ui/icons/Replay";
import {FacebookIcon, FacebookShareButton} from "react-share";
import utils from "../../../../utils/web-utils";


const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        minHeight: "calc(100vh - 56px - 59px - 200px)",
        paddingBottom: "200px",
        backgroundSize: "150%",
        backgroundImage: `url("/images/background_city.png")`,
        backgroundColor: "#FFFBE9",
        backgroundPosition: "right bottom",
        backgroundRepeat: "repeat-x",
        backgroundAttachment: "bottom",
        ['@media (min-width:411px)']: { // eslint-disable-line
            backgroundSize: "110%",
        },
        ['@media (min-width:611px)']: { // eslint-disable-line
            backgroundSize: "75%",
        },
        ['@media (min-width:811px)']: { // eslint-disable-line
            backgroundSize: "58%",
        },
        ['@media (min-width:1111px)']: { // eslint-disable-line
            backgroundSize: "50%",
        },
        ['@media (min-width:1211px)']: { // eslint-disable-line
            backgroundSize: "40%",
        },
    },
    main: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(2),
    },
    title: {
        fontSize: "26px",
        marginTop: "8vw",
        marginRight: "5px",
        paddingLeft: "5px",
        paddingRight: "5px",
        ['@media (min-width: 501px)']: { // eslint-disable-line
            fontSize: "32px",
        },

    },
    subTitle: {
        margin: 0,
        fontSize: "14px",
        fontWeight: "400",
        fontStyle: "italic",
        paddingLeft: "15px",
        paddingRight: "15px",
        ['@media (min-width: 501px)']: { // eslint-disable-line
            fontSize: "16px",
        },
    },
    cleanLink: {
        textDecoration: 'none',
    },
    bottomText: {
        maxWidth: "420px",
    },
    chipList: {
        maxWidth: "400px",
        margin: "4vw 10vw",
        minHeight: "34px"
    },
    chipClass: {
        minWidth: "30px"
    },
    deleteButton: {
        padding: 0,
        margin: "24px 8px 8px 14px",
        float: "left",
        color: "#3c3c3c",
        fontSize: "22px",
        maxWidth: "20px",
        minWidth: "20px"
    },
    demoChip: {
        marginRight: "5px",
        marginLeft: "15px",
        height: "20px",
        width: "20px"
    },
    submitButton: {
        marginTop: "4vw"
    },
    startButton: {
        marginTop: "9vw"
    },
    greenBackground: {
        color: "white",
        fontWeight: "500",
        borderColor: "#1abc9c",
        backgroundColor: "#1abc9c"
    },
    redBackground: {
        color: "white",
        fontWeight: "500",
        borderColor: "#e74c3c",
        backgroundColor: "#e74c3c"
    },
    blueBackground: {
        color: "white",
        fontWeight: "500",
        borderColor: "#3498db",
        backgroundColor: "#3498db"
    },
    tryAgainButton: {
        marginTop: theme.spacing(8),
    },
    facebookShareText: {
        margin: 0
    },
    fbButton: {
        marginTop: theme.spacing(8)
    },
    bottomTitle: {
        marginTop: theme.spacing(8),
        marginBottom: 0
    },
    footer: {
        padding: theme.spacing(8, 2),
        marginTop: 'auto',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    },
}))
export default (props) => {
    const [progress, setProgress] = useState([]);
    const [started, setStarted] = useState(false)
    const [titleText, setTitleText] = useState("Kannt þú íslenska stafrófið?")
    const [subTitleText, setSubTitleText] = useState("Hér er átt við íslenskt nútímastafróf með viðaukunum c, q, w og z.")
    const [submitted, setSubmitted] = useState(false);
    const [finishedCalculations, setFinishedCalculations] = useState(false);
    const [alphabetChips, setAlphabetChips] = useState(<></>);
    const [wrongCharacters, setWrongCharacters] = useState([])
    const [missingCharacters, setMissingCharacters] = useState([])
    const [input, setInput] = useState("");
    const inputEl = useRef(null);

    let endText = ""

    const alphabet = ["A", "Á", "B", "C", "D", "Ð", "E", "É", "F", "G",
        "H", "I", "Í", "J", "K", "L", "M", "N", "O", "Ó", "P", "Q", "R",
        "S", "T", "U", "Ú", "V", "W", "X", "Y", "Ý", "Z", "Þ", "Æ", "Ö"]
    const classes = useStyles();

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    function createChip(item) {
        let backgroundClass = "";
        if (item.color === "green") {
            backgroundClass = classes.greenBackground
        } else if (item.color === "blue") {
            backgroundClass = classes.blueBackground
        } else if (item.color === "red") {
            backgroundClass = classes.redBackground
        }
        return <Chip key={item.character} label={item.character} className={classes.chipClass + " " + backgroundClass} variant="outlined"/>
    }

    const handleSubmit = async () => {
        setSubmitted(true)
        setTitleText("Reikna niðurstöðu")
        setSubTitleText("")

        const progressString = progress.map(item => item.character).join("");
        const longestSeq = lcs(alphabet.join(""), progressString)
        const coloredProgress = colorTheProgress([...progress], longestSeq);
        await animateTheProgress(coloredProgress, 450)

        setFinishedCalculations(true)

        const wrongOrMissing = alphabet.filter(item => !longestSeq.includes(item))
        console.log("wrongOrMissing", wrongOrMissing);
        utils.sendGAEvent("AlphabetGame", "handleSubmit", longestSeq + ", " + wrongOrMissing.join(""))
    }

    const handleChange = e => {
        const {value} = e.target;

        if (value && alphabet.includes(value.toUpperCase())) {
            const foundCharacter = progress.find((item) => value.toUpperCase() === item.character);
            if (typeof foundCharacter === "undefined") {
                const updatedProgress = [...progress, {character: value.toUpperCase()}];
                setProgress(updatedProgress);
                setAlphabetChips(updatedProgress.map(item => <Chip key={item.character} label={item.character} className={classes.chipClass} variant="outlined"/>))
            }
        }

        if (value === "´") {
            setInput(value);
        } else {
            setInput("")
        }
    }

    function removeOneLetter() {
        inputEl.current.focus()
        if (progress.length > 0) {
            const updatedProgress = progress.slice(0, -1);
            setProgress(updatedProgress);
            setAlphabetChips(updatedProgress.map(item => <Chip key={item.character} label={item.character} className={classes.chipClass} variant="outlined"/>))
        }
    }

    function tryAgain() {
        setProgress([]);
        setStarted(false);
        setTitleText("Kannt þú íslenska stafrófið?");
        setSubTitleText("Hér er átt við íslenskt nútímastafróf með viðaukunum c, q, w og z.");
        setSubmitted(false);
        setFinishedCalculations(false);
        setAlphabetChips(<></>);
        setWrongCharacters([]);
        setMissingCharacters([]);
        setInput("");
        utils.sendGAEvent("AlphabetGame", "tryAgain")
    }

    const handleKeyPress = e => {
        const {keyCode} = e;
        if (keyCode === 8) {
            removeOneLetter();
        }
    }

    function lcs(firstSequence, secondSequence) {
        const firstString = firstSequence.toUpperCase();
        const secondString = secondSequence.toUpperCase();

        if (firstString === secondString) {
            return firstString;
        }

        if ((firstString || secondString) === '') {
            return '';
        }

        const firstStringLength = firstString.length;
        const secondStringLength = secondString.length;
        const lcsMatrix = createArray(secondStringLength + 1);

        let i;
        let j;
        for (i = 0; i <= firstStringLength; i++) {
            lcsMatrix[0][i] = 0;
        }

        for (i = 0; i <= secondStringLength; i++) {
            lcsMatrix[i][0] = 0;
        }

        for (i = 1; i <= secondStringLength; i++) {
            for (j = 1; j <= firstStringLength; j++) {
                if (firstString[j - 1] === secondString[i - 1]) {
                    lcsMatrix[i][j] = lcsMatrix[i - 1][j - 1] + 1;
                } else {
                    lcsMatrix[i][j] = Math.max(lcsMatrix[i - 1][j], lcsMatrix[i][j - 1]);
                }
            }
        }

        let lcs = "";
        i = secondStringLength;
        j = firstStringLength;

        while (i > 0 && j > 0) {
            if (firstString[j - 1] === secondString[i - 1]) {
                lcs = firstString[j - 1] + lcs;
                i--;
                j--;
            } else if (Math.max(lcsMatrix[i - 1][j], lcsMatrix[i][j - 1]) === lcsMatrix[i - 1][j]) {
                i--;
            } else {
                j--;
            }
        }
        return lcs;
    }

    function createArray(dimension) {
        const array = [];
        for (let i = 0; i < dimension; i++) {
            array[i] = [];
        }
        return array;
    }

    function colorTheProgress(coloredProgress, longestSeq) {
        for (let i = 0; i < coloredProgress.length; i++) {
            let progressToCheck = coloredProgress[i];
            if (longestSeq.includes(progressToCheck.character)) {
                coloredProgress[i] = {character: progressToCheck.character, color: "green"};
            } else {
                coloredProgress[i] = {character: progressToCheck.character, color: "red"};
            }
        }

        let insertIndex = 0;
        for (let i = 0; i < alphabet.length; i++) {
            const alphabetCharacter = alphabet[i]
            let foundCharacterIndex = coloredProgress.findIndex((item) => alphabetCharacter === item.character)
            if (foundCharacterIndex < 0) {
                coloredProgress.splice(insertIndex, 0, {character: alphabetCharacter, color: "blue"});
                insertIndex++
            } else if (coloredProgress[foundCharacterIndex].color === "green") {
                insertIndex++;
            }
        }
        return coloredProgress;
    }

    async function animateTheProgress(coloredProgress, sleepMs) {
        const wrongCharacters = []
        const missingCharacters = []
        let sleepTime = sleepMs;

        for (let i = 0; i < coloredProgress.length; i++) {
            if (progress[i] && progress[i].character === coloredProgress[i].character) {
                progress[i] = coloredProgress[i]
            } else {
                progress.splice(i, 0, coloredProgress[i]);
            }

            if (coloredProgress[i].color === "red") {
                wrongCharacters.push(coloredProgress[i].character);
                setWrongCharacters(wrongCharacters);
                sleepTime = sleepMs;
            } else if (coloredProgress[i].color === "blue") {
                missingCharacters.push(coloredProgress[i].character);
                setMissingCharacters(missingCharacters);
                sleepTime = sleepMs;
            } else {
                sleepTime = sleepMs / 4;
            }

            setAlphabetChips(progress.map(item => createChip(item)))
            await sleep(sleepTime)
        }
    }

    let wrong = wrongCharacters.length + " " + (wrongCharacters.length > 1 ? "villur" : "villa");
    let missing = missingCharacters.length + " " + (missingCharacters.length > 1 ?  " stafir sem gleymdust" : " stafur sem gleymdist");
    let wrongFb = wrongCharacters.length + " " + (wrongCharacters.length > 1 ? "villur" : "villu");
    let missingFb = missingCharacters.length + " " + (missingCharacters.length > 1 ? "stöfum" : "staf");
    if (wrongCharacters.length === 0 && missingCharacters.length === 0) {
        endText = `Ég náði öllu réttu!`
    } else if (wrongCharacters.length > 5 || missingCharacters.length > 5) {
        endText = `Kannt þú stafrófið?`
    } else if (wrongCharacters.length > 0 && missingCharacters.length > 0) {
        endText = `Ég fékk ${wrongFb} og gleymdi ${missingFb}`
    } else if (wrongCharacters.length > 0) {
        endText = `Ég fékk ${wrongFb}`
    } else {
        endText = `Ég gleymdi ${missingFb}`
    }

    const handleFacebookShare = () => utils.sendGAEvent("AlphabetGame", "facebookShare");
    const handleStartGame = () => {
        setStarted(true);
        utils.sendGAEvent("AlphabetGame", "startGame")
    }

    return (
        <>
            <Box display="flex" alignItems="center" className={classes.root}>
                <h1 className={classes.title}> {titleText} </h1>
                <h4 className={classes.subTitle}> {subTitleText} </h4>

                {!started &&
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleStartGame}
                    className={classes.startButton}
                >
                    Byrja leik
                </Button>
                }

                {started &&
                <>
                    <div className={classes.chipList}>
                        {alphabetChips}
                    </div>
                    {!submitted && progress.length < alphabet.length &&
                    <>
                        <div>
                            <TextField
                                inputRef={inputEl}
                                autoFocus
                                value={input}
                                style={{margin: "8px 0px 8px 8px", float: "left"}}
                                onChange={handleChange}
                                onKeyDown={handleKeyPress}
                                placeholder={progress.length > 0 ? "Næsti stafur" : "Sláðu inn fyrsta staf"}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                            />

                            <IconButton onClick={removeOneLetter} aria-label="delete" className={classes.deleteButton} size="small">

                                {progress.length > 0 &&
                                <BackspaceIcon fontSize="inherit"/>
                                }
                            </IconButton>

                        </div>

                    </>
                    }
                    {!submitted && progress.length > 15 &&
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={handleSubmit}
                        className={classes.submitButton}
                        startIcon={<DoneIcon/>}
                    >
                        Klára leik
                    </Button>
                    }

                    {submitted &&
                    <div>
                        {wrongCharacters.length > 0 &&
                        <>
                            <Chip label={""} className={classes.demoChip + " " + classes.redBackground} variant="outlined"/>
                            {wrong}
                        </>
                        }
                        {missingCharacters.length > 0 &&
                        <>
                            <Chip label={""} className={classes.demoChip + " " + classes.blueBackground} variant="outlined"/>
                            {missing}
                        </>
                        }
                    </div>
                    }

                    {finishedCalculations &&
                    <>
                        {(wrongCharacters.length > 0 || missingCharacters.length > 0) &&
                        <Button
                            onClick={() => tryAgain()}
                            variant="contained"
                            color="primary"
                            className={classes.tryAgainButton}
                            endIcon={<ReplayIcon>send</ReplayIcon>}
                        >
                            Reyna aftur
                        </Button>
                        }

                        {wrongCharacters.length <= 0 && missingCharacters.length <= 0 &&
                        <Box display="flex" justifyContent="center" m={4} p={0}>
                            <p>Vel gert, allt rétt!</p>
                        </Box>
                        }

                        <FacebookShareButton
                            onClick={handleFacebookShare}
                            className={classes.fbButton}
                            url={"https://www.100ord.is/p/stafrofid"}
                            quote={endText}
                        >
                            <FacebookIcon size={32} round/>
                            <p className={classes.facebookShareText}>Deila á Facebook</p>
                        </FacebookShareButton>

                        <h2 className={classes.bottomTitle}> Af hverju þetta stafróf? </h2>
                        <p className={classes.bottomText}>
                            Í íslenska staðlinum ÍST 130:2004 er tilgreint „íslenskt nútímastafróf“ með 36 stöfum en því fylgir þessi athugasemd:
                            „Ofangreint stafróf er stundum nefnt íslenskt nútímastafróf með viðauka, þar sem viðaukinn er
                            stafirnir c, q, w og z.“ Ef viðaukinn er undanskilinn hefur íslenska stafrófið 32 stafi en ekki 36.
                        </p>


                    </>


                    }
                </>
                }

            </Box>
            <Footer className={classes.footer}/>
        </>
    );
}
