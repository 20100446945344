import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Footer from "../../OtherComponents/Footer/Footer";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: "calc(100vh - 56px - 64px - 32px)",
    },
    main: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(2),
    },
    footer: {
        padding: theme.spacing(3, 2),
        marginTop: 'auto',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    },
}));

export default () => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.root}>
                <CssBaseline/>
                <Container component="main" className={classes.main} maxWidth="sm">
                    <Typography variant="h2" component="h1" gutterBottom>
                        404
                    </Typography>
                </Container>
            </div>
            <Footer className={classes.footer}/>
        </>
    );
}
