import {useEffect} from 'react';
import React from 'react';
import {matchPath, useLocation} from 'react-router-dom';
import utils from "../web-utils";
import {useDispatch, useSelector} from "react-redux";
import {setParams, setPath, selectPath} from "./PathAndParamsSlice";

export default () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const path = useSelector(selectPath);

    useEffect(() => {
        utils.initGA();
    }, [])

    useEffect(() => {
        const currentPath = location.pathname;

        if (currentPath !== path) {
            updateParams();
            dispatch(setPath({newState: currentPath}));
            utils.sendGAPageView(currentPath);
        }

        function updateParams() {
            let matchPath1 = matchPath(currentPath, {
                path: '/listi/:code',
                exact: true,
                strict: false
            });
            dispatch(setParams({newState: matchPath1 ? matchPath1.params : {}}));
        }
    }, [location]);


    return null;
}
