import React, {useEffect, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import {fade, makeStyles} from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SettingsIcon from '@material-ui/icons/Settings';
import SearchIcon from '@material-ui/icons/Search';
import withRedux from "next-redux-wrapper";
import Link from "../CustomLink/CustomLink"
import {useDispatch, useSelector} from 'react-redux';
import {setMenuOpen} from './NavBarSlice';
import LoopIcon from "@material-ui/icons/Loop";
import ClearIcon from "@material-ui/icons/Clear";
import apiWrapper from "../../../utils/apiWrapper";
import {selectSearchString, setSearchString} from "../../PublicSpace/FrontPage/FrontPageSlice";
import {setUserState} from "../../UserSlice";
import {initSettings, openSettings} from "./SettingsDrawerSlice";
import Button from "@material-ui/core/Button";
import {selectParams} from "../../../utils/PathAndParamUpdater/PathAndParamsSlice";
import Utils from "../../../utils/web-utils";
import {selectStartedReading, setCardIndex, setShowEmptyNow, setStartedReading} from "../../PublicSpace/WordListViewer/WordListViewerSlice";
import {Refresh} from "@material-ui/icons";
import store from "../../../store/store";
import MenuDrawer from "./MenuDrawer";
import SettingsDrawer from "./SettingsDrawer";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: "#2c3e50"
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
    title: {
        flexGrow: 1,
    },
    search: {
        marginLeft: theme.spacing(6),
        width: 'auto',
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    clearIcon: {
        padding: "0 10px 0 0",
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '12ch',
        '&:focus': {
            width: '20ch',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    logo: {
        marginLeft: "5px",
        marginTop: "6px",
    },
    divider: {
        ['@media (min-width:941px)']: { // eslint-disable-line
            display: "block"
        },
        padding: "0 5px",
        margin: 0,
        display: "none",
        fontWeight: "300",
        fontSize: "24px"
    },
    navBarItem: {
        ['@media (min-width:941px)']: { // eslint-disable-line
            display: "block"
        },
        textDecoration: 'none',
        display: "none",
        fontWeight: "400",
        fontSize: "19px",
        color: "white"
    }
}));

//makeStore function that returns a new store for every request
const makeStore = () => store;


export default withRedux(makeStore)((props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const params = useSelector(selectParams);
    const [iconToShow, setIconToShow] = useState(<></>);
    const searchString = useSelector(selectSearchString);
    const pathUrl = typeof window !== "undefined" ? window.location.pathname : "/";
    const startedReading = useSelector(selectStartedReading);

    useEffect(() => {
        apiWrapper.setAuthStateChanged(dispatch, setUserState);
    }, [])  // eslint-disable-line

    function handleSearch(e) {
        setIconToShow(<LoopIcon/>)
        const {value} = e.target;
        dispatch(setSearchString({newState: value}))
        setIconToShow(<ClearIcon/>)
        if (value.length <= 0) {
            setIconToShow(<></>)
        }
    }

    function clearInput() {
        dispatch(setSearchString({newState: ""}))
        setIconToShow(<></>);
    }

    function hideSearch() {
        return pathUrl !== '/';
    }

    function hideNavLinks() {
        return !pathUrl.includes("/listi/");
    }

    function showSettings() {
        return hideSearch() && !hideNavLinks()
    }

    function openSettingsClick() {
        Utils.sendGAEvent("NavBar", "OpenSettings", params.code ? params.code : "error")
        dispatch(openSettings());
    }

    function readAgain() {
        dispatch(setStartedReading({newState: false}));
        dispatch(setCardIndex({newState: 0}));
        dispatch(setShowEmptyNow({newState: 0}));
        Utils.sendGAEvent("NavBar", "ReadAgain", params.code ? params.code : "error")
    }

    return (
        <>
            <MenuDrawer isExported={props.isExported}/>
            <SettingsDrawer/>
                <AppBar className={classes.appBar} position="static">
                    <Toolbar>
                        <IconButton onClick={() => dispatch(setMenuOpen({newState: true}))} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                            <MenuIcon/>
                        </IconButton>
                        <Typography className={classes.title} variant="h6" noWrap>
                            <a href="/"><img className={"no-select " + classes.logo} height={45} src="/svg/100ORDLOGO.svg" alt="The logo"/></a>
                        </Typography>
                        {hideNavLinks() &&
                        <>
                            <Link isExported={props.isExported} className={classes.navBarItem} to="/leidbeningar" component={Button}>LEIÐBEININGAR</Link>
                            <p className={classes.divider}>/</p>
                            <Link isExported={props.isExported}  className={classes.navBarItem} to="/um100ord" component={Button}>UM 100 ORÐ</Link>

                            {!props.isExported &&
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon/>
                                </div>
                                <InputBase
                                    onChange={handleSearch}
                                    value={searchString}
                                    placeholder="Leita að lista…"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{'aria-label': 'leit'}}
                                />
                                <IconButton onClick={clearInput} className={classes.clearIcon} aria-label="icon">
                                    {iconToShow}
                                </IconButton>
                            </div>
                            }
                        </>
                        }
                        {showSettings() && !startedReading &&
                        <IconButton onClick={openSettingsClick} edge="start" className={"no-select " + classes.menuButton} color="inherit" aria-label="menu">
                            <SettingsIcon/>
                        </IconButton>
                        }
                        {showSettings() && startedReading &&
                        <IconButton onClick={readAgain} edge="start" className={"no-select " + classes.menuButton} color="inherit" aria-label="read again">
                            <Refresh/>
                        </IconButton>
                        }
                    </Toolbar>
                </AppBar>
        </>
    );
})
