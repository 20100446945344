import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ReactMarkdown from "react-markdown";
import Container from "@material-ui/core/Container";


const useStyles = makeStyles({
    root: {
        width: "100%",
        marginBottom: 20,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    date: {
        marginRight: 12,
        fontSize: 14,
        float: "right"
    },
    pos: {
        margin: 12,
        float: "right"
    },
});

export default function SimpleCard(props) {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography className={classes.date} color="textSecondary" gutterBottom>
                    {props.article.date}
                </Typography>
                <Typography variant="h5" component="h2">
                    {props.article.title}
                </Typography>
                <Typography variant="subtitle1" component="p" gutterBottom>
                    <ReactMarkdown source={props.article.body}/>
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                    {props.article.author}
                </Typography>
            </CardContent>

        </Card>
    );
}