import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ListItem from "./ListItem";


const responsive = {
    desktop: {
        breakpoint: {max: 3000, min: 1280},
        items: 4,
        partialVisibilityGutter: 20,
        slidesToSlide: 4
    },
    tablet: {
        breakpoint: {max: 1280, min: 941},
        items: 3,
        partialVisibilityGutter: 20,
        slidesToSlide: 3
    },
    mobile: {
        breakpoint: {max: 940, min: 0},
        items: 2,
        partialVisibilityGutter: 20,
        slidesToSlide: 2
    }
};


const useStyles = makeStyles(() => ({
    title: {
        flexGrow: 1,
        fontSize: "18px",
        fontWeight: "500",
        marginBottom: "4px",
        ['@media (min-width:630px)']: { // eslint-disable-line no-useless-computed-key
            marginBottom: "8px",
            fontSize: "22px",
        },
        ['@media (min-width:1800px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "26px",
        },
    },
    carouselSmall: {
        marginRight: "40px",
        marginLeft: "0px",
    },
    carouselSmallFull: {
        marginLeft: "-40px",
    },
    carouselMedium: {
        marginRight: "60px",
        marginLeft: "0px",
    },
    carouselMediumFull: {
        marginLeft: "-60px",
    },
    carouselLarge: {
        marginRight: "80px",
        marginLeft: "0px",
    },
    carouselLargeFull: {
        marginLeft: "-80px",
    },
}));

export default (props) => {
    const classes = useStyles();
    let carouselItems = <></>
    let carouselClass = "";
    if (props.windowWidth > responsive.desktop.breakpoint.min && props.lists) {
        if (props.lists.length < responsive.desktop.items) {
            carouselClass = classes.carouselLarge;
        } else if (props.lists.length === responsive.desktop.items) {
            carouselClass = `${classes.carouselLarge} ${classes.carouselLargeFull}`
        }
    } else if (props.windowWidth >= responsive.tablet.breakpoint.min && props.lists) {
        if (props.lists.length < responsive.tablet.items) {
            carouselClass = classes.carouselMedium;
        } else if (props.lists.length === responsive.tablet.items) {
            carouselClass = `${classes.carouselMedium} ${classes.carouselMediumFull}`
        }
    } else if (props.windowWidth >= responsive.mobile.breakpoint.min && props.lists) {
        if (props.lists.length < responsive.mobile.items) {
            carouselClass = classes.carouselSmall;
        } else if (props.lists.length === responsive.mobile.items) {
            carouselClass = `${classes.carouselSmall} ${classes.carouselSmallFull}`
        }
    }

    if (props.lists) {
        carouselItems = props.lists.map(list => {
            return <ListItem key={list.code} list={list}/>;
        })
    }

    return (
        <>
            <p className={classes.title}>{props.category}</p>
            <Carousel
                partialVisible
                className={carouselClass}
                draggable={false}
                responsive={responsive}
                transitionDuration={500}
                containerClass="carousel-container"
            >
                {carouselItems}
            </Carousel>
        </>
    );
}
