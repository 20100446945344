import {Textfit} from 'react-textfit';
import React from "react";
import {selectMinWh, selectWidth} from "../../../utils/WithAndHeightUpdater/WithAndHeightSlice";
import {useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {selectAllCaps} from "../../OtherComponents/NavBar/SettingsDrawerSlice";

export default (props) => {
    const useStyles = makeStyles(() => (
        {
            centeredText: {
                textAlign: "center",
                position: "absolute",
                width: "100%",
                display: "table-cell",
                top: "18vh",
                ['@media (min-width:900px)']: { // eslint-disable-line
                    top: "10vh",
                },
            }
        }))

    const upperCase = (text) => text.toUpperCase();
    const lowerCase = (text) => text.toLowerCase();
    const boldIfSame = (a, b, index) => a.toUpperCase() === b.toUpperCase() ? <strong key={index}>{a}</strong> : a

    const windowWidth = useSelector(selectWidth);
    const minWh = useSelector(selectMinWh);
    const allCaps = useSelector(selectAllCaps);
    const classes = useStyles();

    const word = allCaps && props.item.word ? props.item.word.toUpperCase() : props.item.word;
    return (
        <div className={classes.centeredText}>
            <img style={{marginBottom: "5px"}} className={"no-select"} height={minWh * 0.4} src={props.item.image} alt={"Mynd af " + props.item.word}/>
            {props.item.desc &&
            <Textfit
                mode="single"
                max={windowWidth * 0.08}>
                {upperCase(props.item.desc) + " " + lowerCase(props.item.desc)}
            </Textfit>
            }
            {word && word !== " " &&
            <Textfit
                mode="single"
                max={windowWidth * 0.05}>
                {word.split("").map((c, index) => boldIfSame(c, props.item.desc, index))}
            </Textfit>
            }
        </div>
    );
}
