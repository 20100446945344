import {createSlice} from '@reduxjs/toolkit';

export const slice = createSlice({
    name: 'wordListViewer',
    initialState: {
        startedReading: false,
        cardIndex: 0,
        showEmptyNow: 0
    },
    reducers: {
        setStartedReading: (state, action) => {
            state.startedReading = action.payload.newState;
        },
        setCardIndex: (state, action) => {
            state.cardIndex = action.payload.newState;
        },
        setShowEmptyNow: (state, action) => {
            state.showEmptyNow = action.payload.newState;
        },
    },
});

export const selectStartedReading = state => state.wordListViewer.startedReading;
export const selectCardIndex = state => state.wordListViewer.cardIndex;
export const selectShowEmptyNow = state => state.wordListViewer.showEmptyNow;
export const {setStartedReading, setCardIndex, setShowEmptyNow} = slice.actions;

export default slice.reducer;