import React, {useEffect, useState} from 'react';
import ListItem from "@material-ui/core/ListItem";
import {makeStyles} from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import apiWrapper from "../../../utils/apiWrapper";
import soundUtil from "../../OtherComponents/SoundPlayer/soundUtil";
import {setToastOpen} from "../../OtherComponents/Toast/ToastSlice";
import {useDispatch} from "react-redux";
import {setSoundToPlay} from "../../OtherComponents/SoundPlayer/SoundPlayerSlice";
import Chip from "@material-ui/core/Chip";


const useStyles = makeStyles((theme) => ({
    inputField: {
        margin: (theme.spacing(2), theme.spacing(1)),
        flex: 1,
    },
    chip: {
        margin: 1,
    },
    chipList: {
        margin: "0 15px 0 18px"
    },
}));

export default (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [foundSounds, setFoundSounds] = useState({});
    const [noSound, setNoSound] = useState({});
    const [missingSounds, setMissingSounds] = useState([]);

    let handleToggle = () => {
        const newValue = !props.listDetails[props.name];
        props.handleChange({target: {value: newValue, name: props.name}})
        if (newValue) {
            updateSoundListForListDetails(foundSounds)
        }
    }

    const updateSoundListForListDetails = (soundList) => {
        props.handleChange({target: {value: soundList, name: props.name+"List"}})

    }


    async function fetchData() {
        let wordList = [];
        if (props.listDetails.list && props.listDetails.list[0].word) {
            for (const listElement of props.listDetails.list) {
                wordList.push(listElement.word)
            }
        } else {
            wordList = props.listDetails.list;
        }

        if (!wordList){
            return
        }

        const sounds = await apiWrapper.getWordSoundList();

        let found = {}
        let noSound = {}
        let missing = []
        for (const listElement of wordList) {
            if (sounds[listElement]) {
                if (sounds[listElement].provider === "islex") {
                    found[listElement] = sounds[listElement];
                } else {
                    noSound[listElement] = sounds[listElement];
                }
            } else if (listElement.trim().length > 0) {
                missing.push(listElement)
            }
        }
        setFoundSounds(found)
        setNoSound(noSound)
        setMissingSounds(missing)
    }

    const handleAddWord = async (word) => {
        const foundWord = await soundUtil.addWord(word)
        await fetchData().catch(console.error);
        if (foundWord && foundWord[word]) {
            playSound(foundWord[word])
        } else {
            dispatch(setToastOpen({newState: true, message: "Orð fannst ekki", severity: "warning"}));
        }
        updateSoundListForListDetails(foundSounds)
    }


    const handleDeleteSound = async (word) => {
        let notFoundItem = {};
        notFoundItem[word] = {provider: "none"}
        await apiWrapper.addSoundForWork(notFoundItem)
        await fetchData().catch(console.error);
    }

    const playSound = (word) => {
        dispatch(setSoundToPlay({newState: soundUtil.getSoundUrlForWord(word)}));
    }

    useEffect(() => {
        fetchData().catch(console.error);
    }, [props.listDetails.list, props.listDetails[props.name]]);

    let toggled = props.listDetails[props.name];
    return (
        <>
            <ListItem>
                <p>{props.title}</p>
                <Switch
                    label={props.title}
                    edge="end"
                    onChange={handleToggle}
                    checked={props.listDetails[props.name] != null ? props.listDetails[props.name] : false}
                />
            </ListItem>

            {/*<Chip key={i} size="small" label={item} className={classes.chip}/>)*/}
            {toggled &&
            <ListItem>
                <div>
                    <div>
                        <h3>Fundið</h3>
                        <div className={classes.chipList}>

                        {Object.entries(foundSounds).map(([key, value], index) => (
                            <Chip key={value + index} onClick={() => playSound(value)} label={key} onDelete={() => handleDeleteSound(key)}/>
                        ))}
                        </div>
                    </div>

                    {Object.entries(noSound).length > 0 &&
                    <div>
                        <div className={classes.chipList}>

                        <h3>Vantar eða rangt</h3>
                        {Object.entries(noSound).map(([key, value], index) => (
                            <Chip key={value + index} onClick={() => handleAddWord(key)} label={key}/>
                        ))}
                        </div>
                    </div>
                    }
                    {missingSounds.length > 0 &&
                    <div>
                        <h3>Eftir að sækja</h3>
                        <div className={classes.chipList}>
                        {missingSounds.map((value, index) => (
                            <Chip key={value + index} onClick={() => handleAddWord(value)} label={value}/>
                        ))}
                        </div>
                    </div>
                    }
                </div>
            </ListItem>
            }
        </>
    );
}
