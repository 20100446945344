import React from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';

import {useSelector, useDispatch} from 'react-redux';
import {
    closeSettings,
    openSettings,
    selectSettingsState,
    setRandomize,
    setAllCaps,
    selectRandomize,
    setEmptyBetween,
    selectEmptyBetween,
    setBackgroundColor,
    setFontFamily,
    selectBackgroundColor,
    selectFontFamily,
    setWordListLength,
    selectAllCaps,
    selectListenToWord,
    setListenToWord
} from './SettingsDrawerSlice';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import SettingsSwitchItem from "./SettingsItems/SettingsSwitch";
import SelectListLength from "./SettingsItems/SelectListLength";
import Utils from "../../../utils/web-utils";
import {selectParams} from "../../../utils/PathAndParamUpdater/PathAndParamsSlice";
import IconButton from "@material-ui/core/IconButton";
import {Close} from "@material-ui/icons";

const useStyles = makeStyles(() => ({
    list: {
        margin: 15,
        width: 320,
        height: "100%",
        display: "flex",
        flexDirection: "column"
    },
    fullList: {
        width: 'auto',
    },
    cleanLink: {
        textDecoration: 'none',
    },
    mainList: {
        paddingTop: 0,
        border: "solid 1px rgba(0, 0, 0, 0.12)",
        borderRadius: "5px",
    },
    listItem: {
        paddingBottom: "15px"
    },
    closeButton: {
        float: "right",
        margin: 0
    },
    bigDivide: {
        marginTop: "40px"
    }
}));

export default () => {
    const categoryGA = "SettingsDrawer";
    const classes = useStyles();
    const dispatch = useDispatch();
    const isOpen = useSelector(selectSettingsState);
    const randomize = useSelector(selectRandomize);
    const allCaps = useSelector(selectAllCaps);
    const listenToWord = useSelector(selectListenToWord);
    const emptyBetween = useSelector(selectEmptyBetween);
    const backgroundColor = useSelector(selectBackgroundColor);
    const fontFamily = useSelector(selectFontFamily);
    const params = useSelector(selectParams);
    const listCode = params.code ? params.code : "";

    function listLengthChange(e) {
        Utils.sendGAEvent(categoryGA, "listLengthChange", listCode, e.target.value)
        return dispatch(setWordListLength({newState: e.target.value}));
    }

    function emptyCardBetweenToggle() {
        Utils.sendGAEvent(categoryGA, "emptyCardBetweenToggle", listCode, !emptyBetween ? 1 : 0)
        return dispatch(setEmptyBetween({newState: !emptyBetween}));
    }

    function randomizeListToggle() {
        Utils.sendGAEvent(categoryGA, "randomizeListToggle", listCode, !randomize ? 1 : 0)
        return dispatch(setRandomize({newState: !randomize}));
    }

    function allCapsListToggle() {
        Utils.sendGAEvent(categoryGA, "allCapsListToggle", listCode, !allCaps ? 1 : 0)
        return dispatch(setAllCaps({newState: !allCaps}));
    }

    function listenToWordToggle() {
        Utils.sendGAEvent(categoryGA, "listenToWordToggle", listCode, !listenToWord ? 1 : 0)
        return dispatch(setListenToWord({newState: !listenToWord}));
    }

    function blackBackgroundToggle() {
        Utils.sendGAEvent(categoryGA, "blackBackgroundToggle", listCode, !backgroundColor ? 1 : 0)
        return dispatch(setBackgroundColor({newState: !backgroundColor}));
    }

    function setDyslexicFontToggle() {
        Utils.sendGAEvent(categoryGA, "setDyslexicFontToggle", listCode, fontFamily !== "Open Dyslexic" ? 1 : 0)
        return dispatch(setFontFamily({newState: fontFamily !== "Open Dyslexic"}));
    }

    function handleClose() {
        dispatch(closeSettings())
    }

    const sideList = () => (
        <div className={classes.list} role="presentation">
            <div>
                <IconButton onClick={handleClose} aria-label="close" className={classes.closeButton}>
                    <Close/>
                </IconButton>
            </div>
            <List className={classes.mainList}>
                <ListItem>
                    <Typography variant="h6">
                        Stillingar á lista
                    </Typography>
                </ListItem>

                <ListItem className={classes.listItem}>
                    <SelectListLength listLengthChange={listLengthChange}/>
                </ListItem>

                <ListItem className={classes.listItem}>
                    <SettingsSwitchItem text="Raða handahófskennt" tooltip="Orðalistinn raðast handahófskennt eða eftir orðtíðni" handleToggle={() => randomizeListToggle()} checked={randomize}/>
                </ListItem>


                <Divider className={classes.bigDivide}/>
                <ListItem>
                    <Typography variant="h6">
                        Hönnun og útlit
                    </Typography>
                </ListItem>

                <ListItem>
                    <SettingsSwitchItem text="Svartur bakgrunnur" handleToggle={() => blackBackgroundToggle()} checked={backgroundColor}/>
                </ListItem>

                <ListItem>
                    <SettingsSwitchItem text="Lesblinduletur" tooltip={
                        <>
                            {"Leturgerð frá"} <em>OpenDyslexic.org</em> {"sem getur hjálpað einstaklingum með lesblindu/dyslexíu"}
                        </>
                    } handleToggle={() => setDyslexicFontToggle()} checked={fontFamily === "Open Dyslexic"}/>
                </ListItem>

                <ListItem className={classes.listItem}>
                    <SettingsSwitchItem text="Auður skjár" tooltip={
                        <>
                            {"Notandi fær auðan skjá milli þess sem orð birtast"}
                        </>
                    } handleToggle={() => emptyCardBetweenToggle()} checked={emptyBetween}/>
                </ListItem>

                <ListItem className={classes.listItem}>
                    <SettingsSwitchItem text="Hástafir" tooltip={
                        <>
                            {"Orð birtast í hástöfum"}
                        </>
                    } handleToggle={() => allCapsListToggle()} checked={allCaps}/>
                </ListItem>

                <ListItem className={classes.listItem}>
                    <SettingsSwitchItem text="Hlusta" tooltip={
                        <>
                            {"Notandi getur hlustað á framburð orða"}
                        </>
                    } handleToggle={() => listenToWordToggle()} checked={listenToWord}/>
                </ListItem>
            </List>
        </div>
    );

    return (
        <>
            <SwipeableDrawer
                open={isOpen}
                anchor={'right'}
                onClose={() => dispatch(closeSettings())}
                onOpen={() => dispatch(openSettings({newState: true}))}
            >
                {sideList()}
            </SwipeableDrawer>
        </>
    );
}
