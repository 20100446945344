import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import {closeDialog, selectOpenState, selectDetailsDialogCode, selectIsNewList} from '../ListManager/ListManagerSlice';
import {useDispatch, useSelector} from "react-redux";
import apiWrapper from "../../../utils/apiWrapper";
import SimpleLoading from "../../OtherComponents/Loading/SimpleLoading";
import ListDetailsInput from "./ListDetailsInput";
import ListDetailsCategory from "./ListDetailsCategory";
import ListDetailsWordList from "./ListDetailsWordList";
import ListDetailsDisabled from "./ListDetailsDisabled";
import ListListenToWordSwitch from "./ListListenToWordSwitch";
import Divider from "@material-ui/core/Divider";
import utils from "../../../utils/web-utils";
import ListDetailsTextarea from "./ListDetailsTextfield";
import CategoryScroller from "../../PublicSpace/FrontPage/CategoryScroller";
import {selectWidth} from "../../../utils/WithAndHeightUpdater/WithAndHeightSlice";
import ListDetailsSwitch from "./ListDetailsSwitch";
import ListDetailsImageAndWordList from "./ListDetailsImageAndWordList";


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ListDetailsDialog(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isOpen = useSelector(selectOpenState);
    const listCode = useSelector(selectDetailsDialogCode);
    const isNewList = useSelector(selectIsNewList);
    const windowWidth = useSelector(selectWidth);
    const [listIsEdited, setListIsEdited] = useState(false);
    const [listDetails, setListDetails] = useState({});
    const [listLoading, setListLoading] = useState(new Map());
    const [allCategories, setAllCategories] = useState([{title: "loading..."}]);
    let listEditor = <></>

    const handleClose = () => {
        dispatch(closeDialog())
    };

    const handleSave = async () => {
        try {
            await apiWrapper.addWordList(listDetails)
            setListIsEdited(false)
            setListDetails({})
            dispatch(closeDialog())
            props.reloadParent();
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        fetchData().catch(console.error);
    });


    useEffect(() => {
        setListLoading(true);
    }, [isNewList])

    async function fetchData() {
        if (isNewList && listLoading) {
            const color = Math.floor(Math.random() * 101);
            const background = Math.floor(Math.random() * 101);
            const type = "WordList";
            setListLoading(false);
            setListDetails({type: type, background: color + "," + background, published: false})
            setListIsEdited(true)
            setAllCategories(props.allCategories);

            return
        }

        if (listCode && listCode !== listDetails.code) {
            setListLoading(true);
            setListIsEdited(false)
            let result = await apiWrapper.getWordList(listCode);
            // if (result.type === "WordsAndPictures") {
            //     dispatch(setToastOpen({newState: true, message: "Ekki hægt að breyta listum með myndum", severity: "info"}));
            // } else {
                setListDetails(result)
                setListLoading(false);
            // }
            setAllCategories(props.allCategories);
        }
    }


    const handleChange = e => {
        const {name, value} = e.target;
        handleSideEffect(name, value);
        updateDetailsObject(name, value);
        setListIsEdited(true)
    };

    const updateDetailsObject = (name, value) => {
        setListDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSideEffect = (name, value) => {
        if (isNewList && name === "name") {
            value = utils.codifyString(value)
            updateDetailsObject("code", value)
        }

        if (name === "category") {
            let allCategories = props.allCategories.map(item => item.title)
            let set = new Set([...value, ...allCategories]);
            setAllCategories(Array.from(set).map(item => {
                return {title: item};
            }))
        }
    }

    if (listDetails.type === "WordsAndPictures" || listDetails.type === "WordsDescAndPictures") {
        listEditor = <ListDetailsImageAndWordList title={"Orðalisti"} name={"list"} listDetails={listDetails} handleChange={handleChange}/>;
    } else {
        listEditor = <ListDetailsWordList title={"Orðalisti"} name={"list"} listDetails={listDetails} handleChange={handleChange}/>;
    }

    return (
        <div>
            <Dialog fullScreen open={isOpen} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {listLoading ? "" : listDetails.code}
                        </Typography>
                        {listIsEdited &&
                        <Button color="inherit" onClick={handleSave}>
                            Vista breytingu
                        </Button>
                        }
                    </Toolbar>
                </AppBar>
                {listLoading ?
                    <SimpleLoading/>
                    :
                    <List>
                        <ListDetailsInput title={"Nafn"} name={"name"} listDetails={listDetails} handleChange={handleChange}/>
                        <Divider/>

                        <ListDetailsTextarea title={"Lýsing"} name={"description"} listDetails={listDetails} handleChange={handleChange}/>
                        <div style={{margin: "20px"}}><CategoryScroller windowWidth={windowWidth} category={"Demo"} lists={[listDetails]}/></div>
                        <Divider/>
                        <ListDetailsCategory title={"Flokkar"} name={"category"} allCheckboxItems={allCategories} listDetails={listDetails} handleChange={handleChange}/>
                        <Divider/>
                        {listEditor}
                        <Divider/>
                        {/*<ListDetailsWordList title={"Orðalisti"} name={"list"} listDetails={listDetails} handleChange={handleChange}/>*/}

                        <ListDetailsSwitch title={"Sýnilegur listi"} name={"visible"} listDetails={listDetails} handleChange={handleChange}/>
                        <Divider/>
                        <ListDetailsSwitch title={"Útgefinn listi"} name={"published"} listDetails={listDetails} handleChange={handleChange}/>
                        <Divider/>
                        <ListListenToWordSwitch title={"Leyfa að hlusta á hljóðið"} name={"listenToWord"} listDetails={listDetails} handleChange={handleChange}/>
                        <Divider/>
                        <ListDetailsDisabled title={"Url"} name={"code"} listDetails={listDetails} isNewList={isNewList}/>
                        <ListDetailsDisabled title={"Litaspjald"} name={"background"} listDetails={listDetails} isNewList={isNewList}/>
                        <ListDetailsDisabled title={"Týpa"} name={"type"} listDetails={listDetails} handleChange={handleChange} isNewList={isNewList}/>
                        <Divider/>
                        <ListDetailsDisabled title={"Raða handahófskennt"} name={"randomize"} listDetails={listDetails.settings} isNewList={isNewList}/>
                    </List>
                }
            </Dialog>

        </div>
    );
}