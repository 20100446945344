import React, {useEffect, useState} from 'react';
import ListItem from "@material-ui/core/ListItem";
import Chip from "@material-ui/core/Chip";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import utils from "../../../utils/web-utils";

const useStyles = makeStyles(() => ({
    title: {
        marginLeft: "18px"
    },
    chip: {
        margin: 1,
    },
    chipList: {
        margin: "0 15px 0 18px"
    },
    textField: {
        width: "100%"
    }
}));

export default (props) => {
    const classes = useStyles();
    const [wordList, setWordList] = useState([]);
    const [debounceChange, setDebounceChange] = useState([]);

    useEffect(() => {
        if (props.listDetails[props.name]) {
            let list = props.listDetails[props.name].map(item => item.includes(" ") ? '"' + item + '"' : item);
            setWordList(list.join(" "));
        }
    }, [props.name, props.listDetails])

    function handleChange(e) {
        const {value} = e.target;
        setWordList(value)
        utils.debounce(() => {
            let list = []
            if (value.length > 0) {
                list = value.split(/(".*?"|[^"\s]+)(?=\s*|\s*$)/);
                list = list.map(item => item.replace(/['"]+/g, ''))
                list = list.map(Function.prototype.call, String.prototype.trim)
                list = list.filter(Boolean);
            }
            props.handleChange({target: {value: list, name: props.name}})
        }, debounceChange, setDebounceChange, 1000)
    }

    let chipList = [];
    if (props.listDetails[props.name]) {
        chipList = props.listDetails[props.name].map((item, i) => <Chip key={i} size="small" label={item} className={classes.chip}/>);
    }
    return (
        <>
            <h3 className={classes.title}>{props.title} ({chipList ? chipList.length : ""})</h3>
            <div className={classes.chipList}>
                {chipList}
            </div>
            <ListItem>
                <TextField
                    className={classes.textField}
                    id="filled-multiline-flexible"
                    multiline
                    rowsMax="4"
                    value={wordList}
                    onChange={handleChange}
                    variant="filled"
                />
            </ListItem>
        </>
    );
}