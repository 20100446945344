import {createSlice} from '@reduxjs/toolkit';

export const slice = createSlice({
    name: 'loginModal',
    initialState: {
        dialogOpen: false,
    },
    reducers: {
        setLoginOpenState: (state, action) => {
            state.dialogOpen = action.payload.newState;
        },
    },
});

export const selectOpenState = state => state.loginModal.dialogOpen;
export const {setLoginOpenState} = slice.actions;

export default slice.reducer;