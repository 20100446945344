import * as ReactGA from "react-ga";
import config from "./config";

const utils = {
    debounce: (callback, localHook, setLocalHook, wait) => {
        clearTimeout(localHook);
        setLocalHook(setTimeout(() => callback(), wait));
    },
    codifyString: (string) => {
        if (string.length > 0) {
            string = string.trim();
            string = string.toLowerCase()
            string = replaceAllowedSpecial(string)
            string = string.replace(/\s\s+/g, ' ');
            string = string.replace(/\s+/g, '-')
            string = string.replace(/[^a-z0-9\-]/g, ""); // eslint-disable-line
        }
        return string
    },
    randomizeArray: (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    },
    setWordListLength: (selectedWordlist, wordlistLength) => {

        return selectedWordlist ? selectedWordlist.slice(0, Math.min(wordlistLength, selectedWordlist.length)) : wordlistLength;
    },

    stopEventPropigation: {
        onMouseDown: (e) => e.stopPropagation(),
        onMouseUp: (e) => e.stopPropagation(),
        onMouseLeave: (e) => e.stopPropagation(),
        onTouchStart: (e) => e.stopPropagation(),
        onTouchEnd: (e) => e.stopPropagation(),
    },
    sendGAEvent(category, action, label, value) {
        ReactGA.event({
            category: category,
            action: action,
            label: label,
            value: value
        });
    },

    sendGAPageView(url) {
        ReactGA.set({ page: url });
        ReactGA.pageview(url);
    },

    initGA() {
        ReactGA.initialize(config.analyticsConfig());
    }
}

function replaceAllowedSpecial(string) {
    let replaceList = {
        "ß": "ss", "à": "a", "á": "a", "â": "a", "ã": "a", "ä": "a", "å": "a", "æ": "ae", "ç": "c",
        "è": "e", "é": "e", "ê": "e", "ë": "e", "ì": "i", "í": "i", "î": "i", "ï": "i", "ð": "d",
        "ñ": "n", "ò": "o", "ó": "o", "ô": "o", "õ": "o", "ö": "o", "ø": "o", "ù": "u", "ú": "u",
        "û": "u", "ü": "u", "ý": "y", "þ": "th", "ÿ": "y"
    }
    for (let x in replaceList) {
        string = string.replace(new RegExp(x, 'g'), replaceList[x]);
    }
    return string;
}

export default utils