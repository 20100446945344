import {createSlice} from '@reduxjs/toolkit';

export const slice = createSlice({
    name: 'confirmCancelDialog',
    initialState: {
        dialogOpen: false,
        message: "",
        confirmText: "",
        cancelText: "",
        title: "",
        confirmPayload: "",
    },
    reducers: {
        setDialogOpen: (state, action) => {
            state.dialogOpen = true;
            state.message = action.payload.message;
            state.title = action.payload.title;
            state.confirmText = action.payload.confirmText;
            state.cancelText = action.payload.cancelText;
            state.confirmPayload = action.payload.confirmPayload;
        },
        setDialogClosed: (state, action) => {
            state.dialogOpen = false;
            state.message = "";
            state.confirmText = "";
            state.title = "";
            state.cancelText = "";
            state.confirmPayload = "";
        },
    },
});

export const selectOpenState = state => state.confirmCancelDialog.dialogOpen;
export const selectMessage = state => state.confirmCancelDialog.message;
export const selectConfirmText = state => state.confirmCancelDialog.confirmText;
export const selectCancelText = state => state.confirmCancelDialog.cancelText;
export const selectTitle = state => state.confirmCancelDialog.title;
export const selectConfirmPayload = state => state.confirmCancelDialog.confirmPayload;
export const {setDialogOpen, setDialogClosed} = slice.actions;

export default slice.reducer;