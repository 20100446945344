import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import apiWrapper from "../../../utils/apiWrapper";
import CategoryScroller from "./CategoryScroller";
import {useSelector} from "react-redux";
import {selectSearchString} from "./FrontPageSlice.js";
import FrontPageSearch from "./FrontPageSearch";
import {selectWidth} from "../../../utils/WithAndHeightUpdater/WithAndHeightSlice";
import SimpleLoading from "../../OtherComponents/Loading/SimpleLoading";
import Footer from "../../OtherComponents/Footer/Footer";
import utils from "../../../utils/web-utils";
import Utils from "../../../utils/web-utils";
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import IconButton from "@material-ui/core/IconButton";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FrontPageTable from "./FrontPageTable";
import SearchListItem from "./SearchListItem";

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    root: {
        marginTop: 10,
        paddingRight: 0,
        maxWidth: "none",
        paddingLeft: theme.spacing(1),
        ['@media (min-width:1280px)']: { // eslint-disable-line no-useless-computed-key
            paddingLeft: theme.spacing(5),
        },
        ['@media (min-width:940px)']: { // eslint-disable-line no-useless-computed-key
            paddingLeft: theme.spacing(3),
        },
    },
    mainView: {
        paddingBottom: "30px"
    },
    frontPageSearch: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
    },
    miniMenu: {
        height: "20px",
        position: 'absolute',
        top: "76px",
        right: "5px",
        ['@media (max-width:599px)']: { // eslint-disable-line
            top: "110px",
            right: "10px"
        },
    },
    miniMenuButton: {
        marginRight: "8px"
    }
}));

export default () => {
    const [fetchedLists, setFetchedListsList] = useState([]);
    const [listView, setListView] = useState(false);
    const [scrollEleDebounce, setScrollEleDebounce] = useState([]);
    const [searchDebounce, setSearchDebounce] = useState([]);
    const [categorizedList, setCategorizedList] = useState(new Map());
    const [filteredLists, setFilteredLists] = useState(new Map());
    const [categoryScrollerList, setCategoryScrollerList] = useState(null);
    const searchString = useSelector(selectSearchString);
    const windowWidth = useSelector(selectWidth);

    useEffect(() => {
        fetchData().catch(console.error);
    }, []);

    useEffect(() => {
        utils.debounce(() => filterForSearch(searchString), searchDebounce, setSearchDebounce, 300)
    }, [searchString]); // eslint-disable-line

    useEffect(() => {
        utils.debounce(generateScrollerElements, scrollEleDebounce, setScrollEleDebounce, 150)
    }, [filteredLists, categorizedList, windowWidth, listView]); // eslint-disable-line

    const handleListViewToggle = () => {
        setListView(!listView);
    }

    const filterForSearch = (value) => {
        if (value.length > 0) {
            let filter = fetchedLists.filter(x => new RegExp(value.trim().replace(/ /g, ".*"), "gi").test(x.name));
            let filterMap = new Map()
            filterMap.set('Leit', filter)
            setFilteredLists(filterMap)
            Utils.sendGAEvent("FrontPage", "Search", value)
        } else {
            setFilteredLists(new Map());
        }
    };

    const fetchData = async () => {
        const {fetchedLists, categorizedList} = await apiWrapper.getAllListsCategorized();
        const {orderedCategories, notOrderedCategories} = await apiWrapper.getFrontPageOrder(categorizedList)
        let orderedCategoryList = new Map();

        for (const orderedCategory of [...orderedCategories, ...notOrderedCategories]) {

            let listsInCategory = categorizedList.get(orderedCategory.code);
            if (orderedCategory.listOrder && listsInCategory) {
                let orderedLists = orderedCategory.listOrder.map(l => listsInCategory.find(list => l === list.code)).filter(l => l != null)
                let notOrderedLists = listsInCategory.filter((l) => !orderedCategory.listOrder.includes(l.code))
                listsInCategory = [...orderedLists, ...notOrderedLists]
            }
            orderedCategoryList.set(orderedCategory.code, listsInCategory)
        }
        setFetchedListsList(fetchedLists);
        setCategorizedList(orderedCategoryList);
    }

    const generateScrollerElements = () => {
        if (categorizedList.size > 0) {
            if (listView) {
                if (filteredLists.has("Leit")) {
                    setCategoryScrollerList(<FrontPageTable lists={filteredLists.get("Leit")}/>)
                } else {
                    setCategoryScrollerList(<FrontPageTable lists={fetchedLists}/>)
                }
                return
            }

            if (filteredLists.has("Leit")) {
                setCategoryScrollerList(Array.from(filteredLists).map(([key, lists]) =>
                    <SearchListItem key={key} title={key} lists={lists}/>
                ));
            } else {
                setCategoryScrollerList(Array.from(categorizedList).map(([key, lists]) =>
                    <CategoryScroller windowWidth={windowWidth} key={key} category={key} lists={lists}/>
                ));
            }
        }
    };

    const listViewIcon = listView ? <ViewComfyIcon/> : <FormatListBulletedIcon/>;

    const classes = useStyles();
    return (
        <>
            {!categoryScrollerList && <SimpleLoading/>}

            <Container className={classes.root}>
                <Grid container>
                    <div className={classes.miniMenu}>
                        <IconButton aria-label="delete" size="small" className={classes.miniMenuButton} onClick={handleListViewToggle}>
                            {listViewIcon}
                        </IconButton>
                    </div>
                    <Grid className={classes.frontPageSearch} item xs={8} md={8} xl={6}>
                        <FrontPageSearch/>
                    </Grid>
                    <Grid item xs={12} className={classes.mainView}>
                        {categoryScrollerList}
                    </Grid>
                </Grid>
            </Container>

            {categoryScrollerList && <Footer/>}
        </>
    );
}
