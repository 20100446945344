import React, {forwardRef} from 'react';
import MaterialTable from "material-table";
import {useHistory} from "react-router-dom";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import tableIconConfig from "../../../utils/tableIconConfig";
import LinesEllipsis from "react-lines-ellipsis";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
    description: {
        whiteSpace: "pre-line",
    }
})

export default (props) => {
    const history = useHistory();
    const classes = useStyles();

    const handleClickOpen = (code) => {
        history.push("/listi/" + code)
    };

    return (
        <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
                <div style={{maxWidth: "100%", marginTop: "50px"}}>
                    <MaterialTable
                        icons={tableIconConfig}
                        options={{
                            pageSizeOptions: [25, 50, 100, 200],
                            emptyRowsWhenPaging: false,
                            search: false,
                            pageSize: 25,
                            showTitle: false,
                            toolbar: false,
                        }}
                        localization={{
                            header: {
                                actions: ""
                            }
                        }}
                        columns={[
                            {
                                title: "Nafn",
                                field: "name",
                                defaultSort: "asc"
                            },
                            {
                                title: "Lýsing",
                                field: "description",
                                render: rowData => <LinesEllipsis
                                    text={rowData.description}
                                    maxLine={2}
                                    ellipsis=""
                                    className={classes.description}
                                />
                            },
                            {
                                title: "",
                                width: 140,
                                render:  rowData =>  <Button onClick={() => handleClickOpen(rowData.code)}> Lesa lista</Button>
                            },
                        ]}
                        data={props.lists}
                    />
                </div>
            </Grid>
        </Grid>
    );
}
