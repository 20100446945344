import React from 'react';
import './App.css';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import FrontPage from "./components/PublicSpace/FrontPage/FrontPage";
import WordListViewer from "./components/PublicSpace/WordListViewer/WordListViewer";
import Error404 from "./components/PublicSpace/Error404/Error404";
import NavBar from "./components/OtherComponents/NavBar/NavBar";
import ListCreator from "./components/AdminSpace/ListManager/ListManager";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import LoginModal from "./components/OtherComponents/LoginModal/LoginModal";
import Toast from "./components/OtherComponents/Toast/Toast";
import WidthAndHeightUpdater from "./utils/WithAndHeightUpdater/WidthAndHeightUpdater";
import FrontPageOrder from "./components/AdminSpace/FrontPageOrder/FrontPageOrder";
import SoundPage from "./components/AdminSpace/SoundPage/SoundPage";
import News from "./components/PublicSpace/News/News";
import AboutUs from "./components/PublicSpace/AboutUs/AboutUs";
import Tutorial from "./components/PublicSpace/Tutorial/Tutorial";
import AlphabetFacebook from "./components/PublicSpace/Games/AlphabetFacebook/AlphabetFacebook";
import CookiesPage from "./components/PublicSpace/CookiesPage/CookiesPage";
import CookiePopUp from "./components/OtherComponents/CookiePopUp/CookiePopUp";
import GoogleAnalyticsPageView from "./utils/PathAndParamUpdater/PathAndParamsUpdater";
import SoundPlayer from "./components/OtherComponents/SoundPlayer/SoundPlayer";


function App() {



    const theme = createMuiTheme({
        typography: {
            fontFamily: [
                '"Helvetica Neue"',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <NavBar/>
                <Switch>
                    <Route exact path="/listi/:code" component={WordListViewer}/>
                    <Route exact path="/p/stafrofid" component={AlphabetFacebook}/>
                    <Route exact path="/404" component={Error404}/>
                    <Route exact path="/" component={FrontPage}/>
                    <Route exact path="/vefkokur" component={CookiesPage}/>
                    <Route exact path="/stjornbord" component={ListCreator}/>
                    <Route exact path="/front-page-order" component={FrontPageOrder}/>
                    <Route exact path="/sounds" component={SoundPage}/>
                    <Route exact path="/frettir" component={News}/>
                    <Route exact path="/um100ord" component={AboutUs}/>
                    <Route exact path="/leidbeningar" component={Tutorial}/>
                    <Route path="*" component={Error404}/>
                </Switch>
                <LoginModal/>
                <Toast/>
                <CookiePopUp/>
                <WidthAndHeightUpdater/>
                <GoogleAnalyticsPageView/>
                <SoundPlayer/>
            </Router>
        </ThemeProvider>
    );
}

export default App;
