import React, {useEffect, useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles} from "@material-ui/core/styles";
import apiWrapper from "../../../utils/apiWrapper";
import Container from "@material-ui/core/Container";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import {ArrowUpward} from "@material-ui/icons";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {setToastOpen} from "../../OtherComponents/Toast/ToastSlice";
import {useDispatch} from "react-redux";
import OrderItem from "./OrderItem";

const useStyles = makeStyles((theme) => ({
    icon: {
        fontSize: "30px"
    },
    categoryCard: {
        textAlign: 'center',
        fontSize: 26
    }
}));

export default () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [orderedCategories, setOrderedCategories] = useState([]);
    const [notOrderedCategories, setNotOrderedCategories] = useState([]);

    const [categorizedList, setCategorizedList] = useState(new Map());

    useEffect(() => {
        fetchData().catch(console.error);
    }, []);

    const fetchData = async () => {
        const {fetchedLists, categorizedList} = await apiWrapper.getAllListsCategorized();
        const {orderedCategories, notOrderedCategories} = await apiWrapper.getFrontPageOrder();
        setOrderedCategories(orderedCategories);
        setNotOrderedCategories(notOrderedCategories);
        let orderedCategoryList = new Map();

        for (const orderedCategory of [...orderedCategories]) {
            orderedCategoryList.set(orderedCategory, categorizedList.get(orderedCategory.code))
        }
        setCategorizedList(orderedCategoryList);
    }

    async function swapIt(index, change) {
        const updatedList = await swapThemNow(index, change, orderedCategories)
        await apiWrapper.saveFrontPageOrder(updatedList)
        fetchData().catch(console.error);
    }

    async function addToOrdered(index) {
        await apiWrapper.saveFrontPageOrder([...orderedCategories, notOrderedCategories[index]])
        fetchData().catch(console.error);
    }

    async function addOrderedListToCategory(index, listCode) {
        if (orderedCategories[index].listOrder) {
            orderedCategories[index].listOrder.push(listCode)
        } else {
            orderedCategories[index].listOrder = [listCode]
        }
        await apiWrapper.saveFrontPageOrder([...orderedCategories])
        fetchData().catch(console.error);
    }

    async function swapOrderedListInCategory(categoryIndex, itemIndex, change) {
        if (orderedCategories[categoryIndex].listOrder) {
            orderedCategories[categoryIndex].listOrder = await swapThemNow(itemIndex, change, orderedCategories[categoryIndex].listOrder)
            await apiWrapper.saveFrontPageOrder(orderedCategories)
            fetchData().catch(console.error);
        }
    }

    async function swapThemNow(index, change, list) {
        Array.prototype.swapItems = function (a, b) { // eslint-disable-line
            this[a] = this.splice(b, 1, this[a])[0];
            return this;
        }
        try {
            if (index + change < list.length) {
                return list.swapItems(index, index + change)
            } else {
                list.splice(index, 1)
                return list
            }
        } catch (e) {
            dispatch(setToastOpen({newState: true, message: "Tókst ekki að uppfæra lista", severity: "error"}));
            fetchData().catch(console.error);
        }
    }

    const orderedItems = Array.from(categorizedList).map(([category, lists], index) =>
        <Grid key={index} container spacing={3}>
            <Grid item xs={8}>
                <Paper className={classes.categoryCard}>
                    {orderedCategories.length > 0 &&
                    <OrderItem key={category.code} index={index} title={category.code} listOrder={category.listOrder} lists={lists}
                               handleSwap={swapOrderedListInCategory}
                               handleAdd={addOrderedListToCategory}/>
                    }
                </Paper>
            </Grid>

            <Grid item xs={4}>
                {index !== 0 &&
                <IconButton onClick={() => swapIt(index, -1)} size="small">
                    <ArrowUpward fontSize="inherit"/>
                </IconButton>
                }
                <IconButton onClick={() => swapIt(index, 1)} aria-label="delete" size="small">
                    <ArrowDownwardIcon fontSize="inherit"/>
                </IconButton>
            </Grid>
        </Grid>
    );


    const notOrderedItems = notOrderedCategories.map((key, index) =>
        <Grid key={index} container spacing={3}>
            <Grid item xs={8}>
                <Paper className={classes.categoryCard}> {key.code}</Paper>
            </Grid>
            <Grid item xs={4}>
                <IconButton onClick={() => addToOrdered(index)} className={classes.margin} size="small">
                    <ArrowUpward fontSize="inherit"/>
                </IconButton>
            </Grid>
        </Grid>
    );

    return (
        <Container maxWidth="lg">
            <h1> Röðun flokka á forsíðu </h1>

            <h2> Raðað</h2>
            <ul>
                {orderedItems}
            </ul>

            <h2> Ekki raðað </h2>
            <ul>
                {notOrderedItems}
            </ul>
        </Container>
    );
}
