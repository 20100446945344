import {createSlice} from '@reduxjs/toolkit';

export const slice = createSlice({
    name: 'menu',
    initialState: {
        menuOpen: false,
    },
    reducers: {
        setMenuOpen: (state, action) => {
            state.menuOpen = action.payload.newState;
        },
    },
});


export const selectOpenState = state => state.menu.menuOpen;
export const {setMenuOpen} = slice.actions;

export default slice.reducer;