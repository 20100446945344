import {createSlice} from '@reduxjs/toolkit';

export const slice = createSlice({
    name: 'soundPlayer',
    initialState: {
        soundToPlay: null
    },
    reducers: {
        setSoundToPlay: (state, action) => {
            state.soundToPlay = action.payload.newState;
        },
    },
});

export const selectSoundToPlay = state => state.soundPlayer.soundToPlay;
export const {setSoundToPlay} = slice.actions;

export default slice.reducer;