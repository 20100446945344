import React from 'react';
import Fab from "@material-ui/core/Fab";
import {Add, Email, Facebook} from "@material-ui/icons";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#2c3e50"
    },
    margin: {
        margin: theme.spacing(1),
    },
    footerText: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        color: "white"
    }
}));

export default () => {
    const classes = useStyles();
    return (
        <footer className={classes.root}>
            <Box display="flex" justifyContent="center" alignItems="center">
                <span className={classes.footerText}> © 2020 Allur réttur áskilinn </span>
                <Link target="_blank" rel="noreferrer" href="https://www.facebook.com/100ord.is">
                    <Fab size="small" color="secondary" aria-label="add" className={classes.margin}>
                        <Facebook/>
                    </Fab>
                </Link>
                <Link href="mailto:100ord@100ord.is">
                    <Fab size="small" color="secondary" aria-label="add" className={classes.margin}>
                        <Email/>
                    </Fab>
                </Link>
            </Box>
        </footer>
    );
}