import React from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListIcon from '@material-ui/icons/List';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HomeIcon from '@material-ui/icons/Home';
import HeadsetIcon from '@material-ui/icons/Headset';
import Link from '../CustomLink/CustomLink';

import {useSelector, useDispatch} from 'react-redux';
import {setMenuOpen, selectOpenState} from './NavBarSlice';
import {makeStyles} from "@material-ui/core/styles";
import {setLoginOpenState} from "../LoginModal/LoginModalSlice";
import {selectLoggedInState} from "../../UserSlice";
import apiManager from "../../../utils/apiWrapper";
import {setToastOpen} from "../Toast/ToastSlice";
import FlareIcon from '@material-ui/icons/Flare';
import IconButton from "@material-ui/core/IconButton";
import {Close} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    list: {
        width: 250,
        height: "100%",
        display: "flex",
        flexDirection: "column"
    },
    fullList: {
        width: 'auto',
    },
    cleanLink: {
        textDecoration: 'none',
    },
    mainList: {
        flexGrow: 1
    },
    closeButton: {
        float: "right",
    }
}));

export default (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isOpen = useSelector(selectOpenState);
    const loggedIn = useSelector(selectLoggedInState);

    const openLoginDialog = () => {
        dispatch(setLoginOpenState({newState: true}))
    };

    const logout = () => {
        apiManager.logout().catch(console.error);
        dispatch(setToastOpen({newState: true, message: "Notandi hefur verið útskráður", severity: "info"}));
    };

    const sideList = () => (
        <div
            className={classes.list}
            role="presentation"
            onClick={(e) => dispatch(setMenuOpen({newState: false}))}
            onKeyDown={(e) => dispatch(setMenuOpen({newState: false}))}
        >
            <List className={classes.mainList}>
                <IconButton onClick={(e) => dispatch(setMenuOpen({newState: false}))} aria-label="close" className={classes.closeButton}>
                    <Close/>
                </IconButton>

                <Link to={"/"} isExported={props.isExported} className={classes.cleanLink} color="inherit">
                    <ListItem button>
                        <ListItemIcon><HomeIcon/></ListItemIcon>
                        <ListItemText primary={"Forsíða"}/>
                    </ListItem>
                </Link>
                <Link to={"/leidbeningar"} isExported={props.isExported} className={classes.cleanLink} color="inherit">
                    <ListItem button>
                        <ListItemIcon/>
                        <ListItemText primary={"Leiðbeiningar"}/>
                    </ListItem>
                </Link>
                <Link to={"/um100ord"} isExported={props.isExported} className={classes.cleanLink} color="inherit">
                    <ListItem button>
                        <ListItemIcon/>
                        <ListItemText primary={"Um 100 orð"}/>
                    </ListItem>
                </Link>
            </List>
            <Divider/>
            {loggedIn && <List>
                <Link to={"/sounds"} className={classes.cleanLink}>
                    <ListItem button key={"Hljóð"}>
                        <ListItemIcon><HeadsetIcon/></ListItemIcon>
                        <ListItemText primary={"Hljóð"}/>
                    </ListItem>
                </Link>
                <Link to={"/front-page-order"} className={classes.cleanLink}>
                    <ListItem button key={"Röðun forsíðu"}>
                        <ListItemIcon><FlareIcon/></ListItemIcon>
                        <ListItemText primary={"Röðun forsíðu"}/>
                    </ListItem>
                </Link>
                <Link to={"/stjornbord"} className={classes.cleanLink}>
                    <ListItem button key={"Yfirlit lista"}>
                        <ListItemIcon><ListIcon/></ListItemIcon>
                        <ListItemText primary={"Yfirlit lista"}/>
                    </ListItem>
                </Link>
            </List>}
            <List>
                {!loggedIn &&
                <ListItem onClick={openLoginDialog} button key={"Innskráning"}>
                    <ListItemIcon><AccountCircleIcon/></ListItemIcon>
                    <ListItemText primary={"Innskráning"}/>
                </ListItem>
                }
                {loggedIn &&
                <ListItem onClick={logout} button key={"Innskráning"}>
                    <ListItemIcon><ExitToAppIcon/></ListItemIcon>
                    <ListItemText primary={"Útskrá"}/>
                </ListItem>
                }
            </List>
        </div>
    );

    return (
        <>
            <SwipeableDrawer
                open={isOpen}
                anchor={'left'}
                onClose={(e) => dispatch(setMenuOpen({newState: false}))}
                onOpen={(e) => dispatch(setMenuOpen({newState: true}))}
            >
                {sideList()}
            </SwipeableDrawer>
        </>
    );
}
