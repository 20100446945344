import {useState, useEffect} from 'react';
import {isMobile} from "react-device-detect";

export default function useLongPress(callback, ms) {
    const [clickEvent, setClickEvent] = useState(false);
    const [wasLongPress, setWasLongPress] = useState(false);

    function stopClickEvent(e) {
        e.stopPropagation()
        e.preventDefault()
        if (!wasLongPress) {
            callback(1);
        }
        setWasLongPress(false);
        setClickEvent(false);
    }

    function mouseLeaveEvent(e) {
        e.stopPropagation()
        setWasLongPress(false);
        setClickEvent(false);
    }

    function startClickEvent(e) {
        e.stopPropagation()
        setClickEvent(true);
    }

    function triggerLongPress() {
        setWasLongPress(true);
        callback(-1);
    }

    useEffect(() => {
        let timerId;
        if (clickEvent) {
            timerId = setTimeout(triggerLongPress, ms);
        } else {
            clearTimeout(timerId);
        }

        return () => {
            clearTimeout(timerId);
        };
    }, [callback, ms, clickEvent]); // eslint-disable-line

    return {
        onMouseDown: (e) => startClickEvent(e),
        onMouseUp: (e) => stopClickEvent(e),
        onMouseLeave: (e) => mouseLeaveEvent(e),
        onTouchStart: (e) => startClickEvent(e),
        onTouchEnd: (e) => stopClickEvent(e),
    };
}