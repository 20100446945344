import React from 'react';
import ListItem from "@material-ui/core/ListItem";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    inputField: {
        margin: (theme.spacing(2), theme.spacing(1)),
        flex: 1,
    },
}));

export default (props) => {
    const classes = useStyles();

    return (
        <> {!props.isNewList && props.listDetails &&
            <ListItem>
                <TextField
                    className={classes.inputField}
                    label={props.title}
                    value={props.listDetails[props.name]}
                    type="text"
                    name={props.name}
                    disabled={true}
                />
            </ListItem>
            }
        </>
    );
}
