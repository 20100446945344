import * as firebase from 'firebase';
import config from "./config";

const LIST_COLLECTION = "lists"
const METADATA_COLLECTION = "metadata"
const LIST_ORDER_DOC = "list-order"
const FILES_COLLECTION = "files"
const SOUND_DOC = "sounds"

// Initialize Firebase
firebase.initializeApp(config.firebaseConfig());
const db = firebase.firestore();
const auth = firebase.auth();

const wrapper = {
    // ---------------------------------------------------------------------------------------------
    // ------------------------------------- Public ------------------------------------------------
    // ---------------------------------------------------------------------------------------------
    getAllLists: async () => {
        try {
            let document = await db.collection(METADATA_COLLECTION).doc(LIST_COLLECTION).get();
            if (document.data()) {
                return document.data().lists;
            } else {
                return Promise.reject(new Error("DOCUMENT NOT FOUND - " + "getAllLists"));
            }
        } catch (error) {
            return Promise.reject(new Error("ERROR GETTING - " + "getAllLists"));
        }
    },
    getAllListsCategorized: async () => {
        try {
            let fetchedLists = await wrapper.getAllLists();
            const categorizedList = new Map();
            fetchedLists = fetchedLists.filter((item) => item.visible !== false && item.published !== false)
            fetchedLists.forEach((item) => {
                if (item.category) {
                    item.category.forEach((category) => {
                        const collection = categorizedList.get(category);
                        if (!collection) {
                            categorizedList.set(category, [item]);
                        } else {
                            collection.push(item);
                        }
                    })
                }
            });
            return {fetchedLists, categorizedList};
        } catch (e) {
            return Promise.reject(new Error("ERROR GETTING all lists categorized"));
        }
    },
    getWordList: async (code) => {
        try {
            let document = await db.collection(LIST_COLLECTION).doc(code).get();
            if (document.data()) {
                return document.data();
            } else {
                return Promise.reject(new Error("DOCUMENT NOT FOUND " + "getWordList"));

            }
        } catch (error) {
            return Promise.reject(new Error("ERROR GETTING - " + "getWordList"));
        }
    },
    getWordSoundList: async (code) => {
        try {
            let document = await db.collection(FILES_COLLECTION).doc(SOUND_DOC).get();
            if (document.data()) {
                return document.data();
            } else {
                return Promise.reject(new Error("DOCUMENT NOT FOUND " + "getWordSoundList"));

            }
        } catch (error) {
            return Promise.reject(new Error("ERROR GETTING - " + "getWordSoundList" + error));
        }
    },
    getFrontPageOrder: async (categorizedList) => {
        let notOrderedCategories = [];
        let orderedCategories = [];
        try {
            if (!categorizedList) {
                const allLists = await wrapper.getAllListsCategorized();
                categorizedList = allLists.categorizedList;
            }
            notOrderedCategories = Array.from(categorizedList).map(([key]) => {
                return {code: key}
            });

            let document = await db.collection(METADATA_COLLECTION).doc(LIST_ORDER_DOC).get();
            if (document.data()) {
                let orderedCategories = await document.data().frontPage;
                notOrderedCategories = notOrderedCategories.filter(function (x) {
                    return orderedCategories.map(oC => oC.code).indexOf(x.code) < 0;
                });
                return {orderedCategories, notOrderedCategories};
            } else {
                return {orderedCategories, notOrderedCategories};
            }
        } catch (error) {
            console.log("error", error);
            return Promise.reject(new Error("ERROR GETTING " + LIST_ORDER_DOC));
        }
    },


    // ---------------------------------------------------------------------------------------------
    // ---------------------------------------- Login ----------------------------------------------
    // ---------------------------------------------------------------------------------------------

    setAuthStateChanged: (dispatch, setUserState) => auth.onAuthStateChanged(user => {
        if (user && user.l) {
            dispatch(setUserState({newState: {id: user.l, email: user.email}}))
            // console.log('user logged in: ', user);
        } else {
            dispatch(setUserState({newState: null}))
        }
    }),
    login: async (username, password) => {
        try {
            await auth.signInWithEmailAndPassword(username, password)
        } catch (e) {
            // Handle Errors here.
            let errorCode = e.code;
            let errorMessage = e.message;
            console.log("Error signing user in", errorCode, errorMessage);
            return Promise.reject(new Error(e));
        }
    },
    logout: async () => {
        try {
            await auth.signOut();
        } catch (e) {
            // Handle Errors here.
            let errorCode = e.code;
            let errorMessage = e.message;
            console.log("Error signing user out", errorCode, errorMessage);
        }
    },


    // ---------------------------------------------------------------------------------------------
    // ---------------------------------------- Admin ----------------------------------------------
    // ---------------------------------------------------------------------------------------------
    addWordList: async (metaListInfo) => {
        try {
            await db.collection(LIST_COLLECTION).doc(metaListInfo.code).set(metaListInfo)

            const metaDataDocument = await db.collection(METADATA_COLLECTION).doc(LIST_COLLECTION).get();
            if (metaDataDocument && metaDataDocument.exists) {

                metaListInfo = JSON.parse(JSON.stringify(metaListInfo))
                metaListInfo.wordCount = metaListInfo.list ? metaListInfo.list.length : 0
                delete metaListInfo.list;
                delete metaListInfo.listenToWordList;

                let metaData = metaDataDocument.data()[LIST_COLLECTION].filter(list => list.code !== metaListInfo.code);
                if (metaListInfo) {
                    metaData.unshift(metaListInfo)
                }
                return metaDataDocument.ref.update({
                    code: LIST_COLLECTION,
                    lists: metaData
                })
            } else {
                return metaDataDocument.ref.set({lists: [metaListInfo]}, {merge: true})
            }
        } catch (e) {
            console.log("e", e);
            return Promise.reject(new Error("ERROR SAVING word list"));
        }
    },
    deleteWordList: async (code) => {
        try {
            await db.collection(LIST_COLLECTION).doc(code).delete()
            const metaDataDocument = await db.collection(METADATA_COLLECTION).doc(LIST_COLLECTION).get();
            if (metaDataDocument && metaDataDocument.exists) {
                let metaData = metaDataDocument.data()[LIST_COLLECTION].filter(list => list.code !== code);
                return metaDataDocument.ref.update({
                    lists: metaData
                })
            }
        } catch (e) {
            console.log("e", e);
            return Promise.reject(new Error("ERROR SAVING deleting list"));
        }
    },
    saveFrontPageOrder: async (newOrderedList) => {
        try {
            await db.collection(METADATA_COLLECTION).doc(LIST_ORDER_DOC).set({code: LIST_ORDER_DOC, frontPage: newOrderedList})
        } catch (e) {
            console.log("e", e);
            return Promise.reject(new Error("ERROR SAVING FrontPageOrder"));
        }
    },
    addSoundForWork: async (metaListInfo) => {
        const metaDataDocument = await db.collection(FILES_COLLECTION).doc(SOUND_DOC).get();
        if (metaDataDocument && metaDataDocument.exists) {
            metaListInfo = JSON.parse(JSON.stringify(metaListInfo))
            metaDataDocument.data()[metaListInfo.word] = metaListInfo;
            return metaDataDocument.ref.update(metaListInfo)
        } else {
            return metaDataDocument.ref.set(metaListInfo, {merge: true})
        }
    }


}

export default wrapper
