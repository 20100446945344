import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import apiWrapper from "../../../utils/apiWrapper";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import {useDispatch} from "react-redux";


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import IconButton from '@material-ui/core/IconButton';
import {setToastOpen} from "../../OtherComponents/Toast/ToastSlice";

import soundUtil from "../../OtherComponents/SoundPlayer/soundUtil";
import {setSoundToPlay} from "../../OtherComponents/SoundPlayer/SoundPlayerSlice";


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    categoryCard: {
        textAlign: 'center',
        fontSize: 26
    },
    inputField: {
        margin: "10px 5px",
        flex: 1,
    },
    addButton: {
        marginTop: "20px"
    }
});

export default function SimpleTable() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [sounds, setSounds] = useState([]);
    const [newWord, setNewWord] = useState([]);

    const playSound = (word) => {
        dispatch(setSoundToPlay({newState: soundUtil.getSoundUrlForWord(word)}));
    }

    const handleChange = e => {
        const {name, value} = e.target;
        setNewWord(value)
    };

    const handleAddWord = async () => {
        const foundWord = await soundUtil.addWord(newWord)
        await fetchData().catch(console.error);
        if (foundWord && foundWord[newWord]) {
            // playSound(foundWord[newWord])
        } else {
            dispatch(setToastOpen({newState: true, message: "Orð fannst ekki", severity: "warning"}));
        }
        setNewWord("");
    }

    useEffect(() => {
        fetchData().catch(console.error);
    }, []);

    const fetchData = async () => {
        const sounds = await apiWrapper.getWordSoundList();
        setSounds(sounds);
    }

    return (
        <Container maxWidth="lg">

            <TextField
                className={classes.inputField}
                label={"Nýtt orð"}
                value={newWord}
                type="text"
                onChange={handleChange}
                name={"Orð"}
            />
            <Button onClick={handleAddWord} className={classes.addButton} variant="contained" color="primary">
                Bæta við
            </Button>

            {/*{sounds &&  <p>{JSON.stringify(sounds)}</p>}*/}
            <h1 className={classes.title}> Orð með hljóðum </h1>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Orð</TableCell>
                            <TableCell align="left">Þjónusta</TableCell>
                            <TableCell align="left">ids</TableCell>
                            <TableCell align="center">Hlusta</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(sounds).map(([key, value]) => (
                            <TableRow key={key}>
                                <TableCell component="th" scope="row">
                                    {key}
                                </TableCell>
                                <TableCell align="left">{value.provider}</TableCell>
                                <TableCell align="left">{value.wordId} - {value.soundId}</TableCell>
                                <TableCell align="center">
                                    <IconButton onClick={() => playSound(value)} aria-label="play sound">
                                        <PlayArrowIcon/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>

    );
}