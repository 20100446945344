import {createSlice} from '@reduxjs/toolkit';

export const slice = createSlice({
    name: 'pathAndParams',
    initialState: {
        path: "",
        params: {}
    },
    reducers: {
        setPath: (state, action) => {
            state.path = action.payload.newState;
        },
        setParams: (state, action) => {
            state.params = action.payload.newState;
        },
    },
});

export const selectPath = state => state.pathAndParams.path;
export const selectParams = state => state.pathAndParams.params;
export const {setPath, setParams} = slice.actions;

export default slice.reducer;