import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Footer from "../../OtherComponents/Footer/Footer";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: "calc(100vh - 56px - 64px - 32px)",
    },
    main: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(2),
    },
    footer: {
        padding: theme.spacing(3, 2),
        marginTop: 'auto',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    },
    h2Class: {
        marginTop: "18px",
        marginBottom: "5px"
    }
}));

export default () => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.root}>
                <CssBaseline/>
                <Container component="main" className={classes.main} maxWidth="sm">
                    <Typography variant="h2" component="h1" className={classes.h2Class}>
                        Þessi vefur notar vefkökur.
                    </Typography>
                    Við notum vefkökur til að bæta upplifun þína á síðunni. Með því að halda áfram að vafra um síðuna samþykkir þú skilmála okkar um notkun á vefkökum, að öðrum kosti getur þú kynnt þér hvernig má stýra notkun þeirra.
                    <Typography variant="h6" component="h2" className={classes.h2Class}>
                        Skilmálar 100 orð um notkun á vefkökum
                    </Typography>
                    Skilmálar þessir eiga við um notkun vefkaka á eftirtöldum lénum og öllum undirlénum sem tilheyra þeim; 100orð.is

                    <Typography variant="h6" component="h2" className={classes.h2Class}>
                        Um vefkökur
                    </Typography>

                    Vefkökur (e. cookies) eru litlar textaskrár sem eru vistaðar á tölvunni þinni eða öðrum snjalltækjum sem þú notar til að heimsækja vefsíðu í fyrsta sinn. Vefkökurnar gera það að verkum að vefsíðan man eftir þér og
                    hvernig þú notaðir síðuna í hvert sinn sem þú heimsækir hana aftur. Vefkökur innihalda ekki persónuupplýsingar á borð við nafnið þitt, netfang, símanúmer eða kennitölu.
                    <Typography variant="h6" component="h2" className={classes.h2Class}>

                        Hvernig við notum við vefkökur
                    </Typography>

                    Vefkökur sem teljast nauðsynlegar gera notandanum kleift að ferðast um vefsíðuna og nota þá virkni sem síðan býður upp á. Vefkökur sem notaðar eru til að bæta virkni vefsíðunnar og auka þannig þjónustu við notendur gera
                    svo með því að t.d. muna innskráningu á Mínar síður.
                    <Typography variant="h6" component="h2" className={classes.h2Class}>
                        Vefmælingar
                    </Typography>

                    Vefkökur eru einnig notaðar til að bæta frammistöðu vefsíðunnar og nýtum við okkur til þess þjónustu fyrirtækja á borð við Google Analytics til vefmælinga og gæðaeftirlits. Upplýsingar sem notaðar eru í þessum tilgangi
                    eru sem dæmi tegund vafra, stýrikerfis og skjástærð notenda, fjöldi og lengd heimsókna, ferðalag notenda og leitarorð. Við notum þessar upplýsingar til að bæta upplifun notenda og við þróun á vefsíðunum okkar.
                    <Typography variant="h6" component="h2" className={classes.h2Class}>
                        Stilla notkun á vefkökum
                    </Typography>

                    Þú getur stýrt því hvernig þú notar vefkökur í þínum vafra, m.a. þannig að notkun þeirra sé hætt.
                    <a href="http://www.allaboutcookies.org/manage-cookies/stop-cookies-installed.html" target="_blank" rel="noopener noreferrer">Hér má finna góðar leiðbeiningar</a>
                     um hvernig þú stillir notkun á vefkökum eða slekkur alfarið á notkun þeirra í hinum ýmsu vöfrum (athugaðu að slóðin opnar nýjan glugga í vafranum þínum).
                </Container>
            </div>
            <Footer className={classes.footer}/>
        </>
    );
}
