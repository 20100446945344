import React from 'react';
import ListItem from "@material-ui/core/ListItem";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

const useStyles = makeStyles((theme) => ({
    inputField: {
        margin: (theme.spacing(2), theme.spacing(1)),
        flex: 1,
    },
}));

export default (props) => {
    const classes = useStyles();

    let handleToggle = () => {
        props.handleChange({target: {value: !props.listDetails[props.name], name: props.name}})
    }

    return (
        <>
            <ListItem>
                <p>{props.title}</p>
                <Switch
                    label={props.title}
                    edge="end"
                    onChange={handleToggle}
                    checked={props.listDetails[props.name] != null ? props.listDetails[props.name] : false}
                />
            </ListItem>
        </>
    );
}
