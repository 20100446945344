import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Footer from "../../OtherComponents/Footer/Footer";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import NewsArticle from "./NewsArticle";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: "calc(100vh - 56px - 64px - 32px)",
    },

    main: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(2),
    },
    footer: {
        padding: theme.spacing(3, 2),
        marginTop: 'auto',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    },
}));

const newsArray = [
        {
        date: "19.02.2021",
        title: "Hljóðupptökur!",
        body: `Nú er hægt að hlusta á framburð mjög margra orða í orðalistunum á 100ord.is! Þegar hljóðupptaka er til fyrir orð birtist hátalaramerki neðst í vinstra horni skjásins sem þarf að smella á til að spila. Við notum hljóðupptökur frá ÍSLEX-orðabókinni en þær eru einstaklega skýrar og góðar. Þessi möguleiki getur nýst mörgum, t.d. þeim sem eru að læra íslensku sem annað mál. 

Við vekjum einnig athygli á þeim ýmsu stillingarmöguleikum sem eru í boði á síðunni. Þá má finna með því að smella á tannhjólið uppi í hægra horninu þegar listi hefur verið valinn. Þar má t.d. stjórna því hvort hægt sé að hlusta á framburð orða, hvort orðin birtist handahófskennt, hvort þau birtist í há- eða lágstöfum o.fl.`,
        author: "Starfsfólk 100 orð"
    }, {
        date: "01.02.2021",
        title: "Tveir nýir myndalistar",
        body: `Nú hafa tveir nýir myndalistar bæst við og svo er fjöldi nýrra og spennandi orðalista í bígerð. \n
Eins erum við að vinna að ýmsum viðbætum og stillingarmöguleikum sem bæta gæði síðunnar.  \n
[Stafir og myndir](/listi/stafir-og-myndir) \n
[Heimilisorð](/listi/heimilisord)`,
        author: "Starfsfólk 100 orð"
    },{
        date: "16.11.2020",
        title: "Kannt þú íslenska stafrófið?",
        body: `
Taktu þátt í einföldum leik og fagnaðu með okkur degi íslenskrar tungu!\n
[Taka þátt](/p/stafrofid) `,
        author: "Starfsfólk 100 orð"
    },
    {
        date: "14.11.2020",
        title: "Tveir nýir listar",
        body: `
Annar listinn inniheldur einungis stutt orð, eins til þriggja stafa, og hentar því mjög vel fyrir byrjendur í lestri. Hinn listinn er skemmtilegur myndalisti \n 
[Stutt orð](/listi/stutt-ord) \n
[Litirnir og hlutir](/listi/litir-og-hlutir)`,
        author: "Starfsfólk 100 orð"
    },
    {
        date: "09.10.2020",
        title: "Ný síða",
        body: `
Við kynnum með stolti nýju vefsíðuna okkar, 100ord.is. Hér má nálgast vandað efni fyrir lestrarkennslu.
        
Ef spurningar vakna má senda okkur línu á [100ord@100ord.is](mailto:100ord@100ord.is).`,
        author: "Starfsfólk 100 orð"
    }]

export default () => {

    const classes = useStyles();
    return (
        <>
            <div className={classes.root}>
                <CssBaseline/>
                <Container component="main" className={classes.main} maxWidth="md">
                    <Typography variant="h2" component="h1" gutterBottom>
                        Fréttir
                    </Typography>
                    {newsArray.map(article => {
                        return <NewsArticle article={article}/>
                    })}
                </Container>
            </div>
            <Footer className={classes.footer}/>
        </>
    );
}
