import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Footer from "../../OtherComponents/Footer/Footer";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import ReactMarkdown from "react-markdown";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: "calc(100vh - 56px - 64px - 32px)",
    },
    main: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(2),
        whiteSpace: "pre-line",
    },
    footer: {
        padding: theme.spacing(3, 2),
        marginTop: 'auto',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    },
}));

export default () => {
    const classes = useStyles();
    const text = `
Vefsíðan er ætluð sem kennsluefni við lestrarnám í grunnskóla og miðar að eflingu sjónræns orðaforða. Vefsíðan gengur út á lestur orða af orðalistum af mismunandi erfiðleikastigi.

Sjálfvirkur lestur orðmynda, sem mynda sjónrænan orðaforða, er forsenda lesfimi en mikilvægt er að börn nái góðum tökum á honum svo svigrúm fáist fyrir lesskilning. Algengustu nokkur hundruð orðin í íslensku mynda mjög hátt hlutfall alls ritaðs texta og því mikilvægt að geta lesið þau á sjálfvirkan hátt.

Listarnir eru m.a. byggðir á afrakstri þverfaglegra rannsókna innan Háskóla Íslands, t.d. á sviði talmeinafræði og málvísinda. Einnig erum við með ýmsa lista sem fengnir eru af Orðtíðnivef Árnastofnunar ásamt sérútbúnum orðalistum úr lestrarbókum grunnskólanema. Að auki eru listar með 100 algengustu orðum ýmissa tungumála.

Höfundar vefsíðunnar eru Dagbjört Guðmunsdóttir (málfræðingur), Einar Örn Bergsson (tölvunarfræðingur), Emil Aron Thorarensen (tölvunarfræðingur) og Lilja Björk Stefánsdóttir (málfræðingur). 

Hægt er að hafa samband við okkur á [100ord@100ord.is](mailto:100ord@100ord.is)  
    `

    const otherText = `
***

#### Hægt er að hlusta á framburð orða í mörgum tilvikum en hljóðupptökurnar eru fengnar frá ÍSLEX-orðabókinni.

Þórdís Úlfarsdóttir og Halldóra Jónsdóttir. (2019). Islex Recordings, CLARIN-IS, Stofnun Árna Magnússonar. http://hdl.handle.net/20.500.12537/6

***
        `

    return (
        <>
            <div className={classes.root}>
                <CssBaseline/>
                <Container component="main" className={classes.main} maxWidth="md">
                    <Typography variant="h2" component="h1" gutterBottom>
                        Um 100 orð
                    </Typography>
                    <Typography variant="subtitle1" component="p" gutterBottom>
                        <ReactMarkdown source={text} />
                    </Typography>
                    <br/>
                    <br/>
                    <Typography variant="subtitle1" component="p" gutterBottom>
                        <ReactMarkdown source={otherText} />
                    </Typography>
                </Container>
            </div>
            <Footer className={classes.footer}/>
        </>
    );
}
