import {createSlice} from '@reduxjs/toolkit';

export const slice = createSlice({
    name: 'detailsDialog',
    initialState: {
        dialogOpen: false,
        listCode: "",
        newCategoryDialogOpen: false,
    },
    reducers: {
        closeDialog: (state, action) => {
            state.dialogOpen = false;
        },
        openCreateListDialog:  (state, action) => {
            state.listCode = "";
            state.dialogOpen = true;
            state.isNewList = true;
        },
        openDialogWithCode:  (state, action) => {
            state.listCode = action.payload.code;
            state.dialogOpen = true;
            state.isNewList = false;
        },
        openCloseNewCategoryDialog:  (state, action) => {
            state.newCategoryDialogOpen = action.payload.code;
        },
    },
});

export const selectOpenState = state => state.detailsDialog.dialogOpen;
export const selectNewCategoryDialogOpen = state => state.detailsDialog.newCategoryDialogOpen;
export const selectDetailsDialogCode = state => state.detailsDialog.listCode;
export const selectIsNewList = state => state.detailsDialog.isNewList;
export const {closeDialog, openDialogWithCode, openCreateListDialog, openCloseNewCategoryDialog} = slice.actions;

export default slice.reducer;