import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {useDispatch, useSelector} from "react-redux";
import {selectOpenState, selectMessage, selectSeverity, setToastOpen} from "./ToastSlice";
import {selectWidth} from "../../../utils/WithAndHeightUpdater/WithAndHeightSlice";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CustomizedSnackbars() {
    const dispatch = useDispatch();
    const open = useSelector(selectOpenState);
    const message = useSelector(selectMessage);
    const severity = useSelector(selectSeverity);
    const windowWidth = useSelector(selectWidth);

    let anchorOrigin = { vertical: 'bottom', horizontal: 'center' }

    if (windowWidth > 770) {
        anchorOrigin = { vertical: 'top', horizontal: 'center' }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(setToastOpen({newState: false, message: message, severity: severity}));
    };

    return (
        <Snackbar open={open} autoHideDuration={6000} anchorOrigin={anchorOrigin} onClose={handleClose}>
            <Alert onClose={handleClose} severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    );
}
