import React from 'react';
import CustomTooltip from "../../InfoBubble/InfoBubble";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Switch from "@material-ui/core/Switch";

export default (props) => {
    return (
        <>
            {props.text}
            {props.tooltip && <CustomTooltip tooltip={props.tooltip}/>}
            <ListItemSecondaryAction>
                <Switch
                    edge="end"
                    onChange={props.handleToggle}
                    checked={props.checked}
                    inputProps={{'aria-labelledby': 'switch-list-label-wifi'}}
                />
            </ListItemSecondaryAction>
        </>
    )
}
