import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from "@material-ui/core/IconButton";
import {ArrowDownward, ArrowUpward} from "@material-ui/icons";
import AccordionDetails from "@material-ui/core/AccordionDetails";

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

export default function SimpleAccordion(props) {
    const classes = useStyles();
    let orderedCategories = []
    let notOrderedItems = []

    if (props.listOrder) {
        orderedCategories = props.listOrder.map((listCode, index) => {
            return <li>
                {listCode}
                {index !== 0 &&
                <IconButton onClick={() => props.handleSwap(props.index, index, -1)} size="small">
                    <ArrowUpward fontSize="inherit"/>
                </IconButton>
                }
                <IconButton onClick={() => props.handleSwap(props.index, index, 1)} size="small">
                    <ArrowDownward fontSize="inherit"/>
                </IconButton>
            </li>
        })
    }

    if (props.lists) {
        let listsFiltered = props.lists.filter((l) => props.listOrder ? !props.listOrder.includes(l.code) : true)
        notOrderedItems = listsFiltered.map(list => {
            return <li>
                {list.code}
                <IconButton onClick={() => props.handleAdd(props.index, list.code)} size="small">
                    <ArrowUpward fontSize="inherit"/>
                </IconButton>
            </li>
        })
    }

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={classes.heading}>{props.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div>
                    <ul style={{textAlign: "left", fontSize: "16px"}}>
                        {orderedCategories}

                        <p> ---- </p>
                        {notOrderedItems}
                    </ul>
                </div>
            </AccordionDetails>
        </Accordion>
    );
}
