import React, {useRef} from 'react';
import ReactHowler from 'react-howler'
import {setSoundToPlay, selectSoundToPlay} from "./SoundPlayerSlice";
import {useDispatch, useSelector} from "react-redux";

export default () => {
    const dispatch = useDispatch();
    const howlerRef = useRef(null);
    const soundToPlay = useSelector(selectSoundToPlay);

    const handleOnEnd = () => {
        dispatch(setSoundToPlay({newState: null}));
    }

    return (
        <>
            {soundToPlay &&
            <ReactHowler
                src={soundToPlay}
                html5={true}
                playing={false}
                ref={howlerRef}
                onLoad={() => {
                    if (howlerRef) {
                        howlerRef.current.play()
                    }

                }}
                onEnd={handleOnEnd}
            />
            }
            </>
    );
}
