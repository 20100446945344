import apiWrapper from "../../../utils/apiWrapper";
import axios from 'axios';

const wrapper = {
    getSoundUrlForWord: (word) => {
        if (word.provider === "islex") {
            const first = word.wordId / 1000;
            const second = word.soundId;
            return 'https://islex.arnastofnun.is/islex-files/audio/' + Math.floor(first) + '/' + second + '.mp3';
        } else {
            return null;
        }
    },
    addWord: async (newWord) => {
        try {
            const wordResponse = await axios.get('/getWordSound', {
                params: {
                    word: newWord
                }
            });

            const wordToAdd = {}
            wordToAdd[newWord] = wordResponse.data
            await apiWrapper.addSoundForWork(wordToAdd)
            return wordToAdd;

        } catch (error) {
            let notFoundItem = {};
            notFoundItem[newWord] = {provider: "none"}
            await apiWrapper.addSoundForWork(notFoundItem)
            return null
        }
    },

}

export default wrapper
