import {Textfit} from 'react-textfit';
import React from "react";
import {selectMinWh, selectWidth} from "../../../utils/WithAndHeightUpdater/WithAndHeightSlice";
import {useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {selectAllCaps} from "../../OtherComponents/NavBar/SettingsDrawerSlice";

export default (props) => {
    const useStyles = makeStyles(() => (
        {
        centeredText: {
            textAlign: "center",

            position: "absolute",
            width: "100%",
            display: "table-cell",
            top: "10vh",
            ['@media (min-width:900px)']: { // eslint-disable-line
                top: "4vh",
            },
        }
    }))

    const windowWidth = useSelector(selectWidth);
    const minWh = useSelector(selectMinWh);
    const allCaps = useSelector(selectAllCaps);
    const classes = useStyles();
    const word = allCaps && props.item.word ? props.item.word.toUpperCase() : props.item.word;

    return (
        <div className={classes.centeredText}>
            <img className={"no-select"}  height={minWh * 0.6} src={props.item.image} alt={"Mynd af " + props.item.word}/>
            <Textfit
                mode="single"
                max={windowWidth * 0.08}>
                {word}
            </Textfit>
        </div>
    );
}
