import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import LoopIcon from '@material-ui/icons/Loop';
import {setSearchString, selectSearchString} from "./FrontPageSlice.js";
import {useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        minHeight: "40px"
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    icon: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

export default function CustomizedInputBase() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [iconToShow, setIconToShow] = useState(<></>);
    const searchString = useSelector(selectSearchString);


    function handleSearch(e) {
        setIconToShow(<LoopIcon/>)
        const {value} = e.target;
        dispatch(setSearchString({newState: value}))
        setIconToShow(<ClearIcon/>)
        if (value.length <= 0) {
            setIconToShow(<></>)
        }
    }

    function clearInput() {
        dispatch(setSearchString({newState: ""}))
        setIconToShow(<></>)
    }

    return (
        <Paper component="form" className={classes.root}>
            <InputBase
                onChange={handleSearch}
                value={searchString}
                className={classes.input}
                placeholder="Leita að lista"
                inputProps={{'aria-label': 'leita að lista'}}
            />
            <IconButton onClick={clearInput} className={classes.icon} aria-label="icon">
                {iconToShow}
            </IconButton>
        </Paper>
    );
}