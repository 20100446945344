import {useCallback, useEffect, useState} from 'react';

export default (props) => {
    const [forwardEvent, setForwardEvent] = useState(false);
    const [backwardEvent, setBackwardEvent] = useState(false);
    const {updateCardIndex} = props;

    const handleUserKeyUp = useCallback(event => {
        setEvents(event, false)
    }, []);
    const handleUserKeyDown = useCallback(event => {
        setEvents(event, true)
    }, []);

    useEffect(() => {
        window.addEventListener('keydown', handleUserKeyDown);
        window.addEventListener('keyup', handleUserKeyUp);
        return () => {
            window.removeEventListener('keydown', handleUserKeyDown);
            window.removeEventListener('keyup', handleUserKeyUp);
        };
    }, []); // eslint-disable-line

    useEffect(() => {
        if (forwardEvent) {
            updateCardIndex(1)
        }
    }, [forwardEvent]); // eslint-disable-line

    useEffect(() => {
        if (backwardEvent) {
            updateCardIndex(-1)
        }
    }, [backwardEvent]); // eslint-disable-line

    function setEvents(event, newState) {
        if (event.keyCode === 32 || event.keyCode === 39) {
            event.preventDefault();
            setForwardEvent(newState)
        } else if (event.keyCode === 37) {
            event.preventDefault();
            setBackwardEvent(newState)
        }
    }

    return null;
}
