import React from 'react';
import ListItem from "@material-ui/core/ListItem";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    textArea: {
        margin: (theme.spacing(2), theme.spacing(1)),
        flex: 1,
    },
}));

export default (props) => {
    const classes = useStyles();

    return (
        <>
            <ListItem>
                <TextField
                    className={classes.textArea}
                    id="filled-multiline-flexible"
                    multiline
                    rowsMax="5"
                    label={props.title}
                    value={props.listDetails[props.name]}
                    onChange={props.handleChange}
                    name={props.name}
                    variant="filled"
                />

            </ListItem>
        </>
    );
}
