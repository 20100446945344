import {createSlice} from '@reduxjs/toolkit';

export const slice = createSlice({
    name: 'user',
    initialState: {
        user: null,
    },
    reducers: {
        setUserState: (state, action) => {
            state.user = action.payload.newState;
        },
    },
});

export const selectUserObject = state => state.user.user;
export const selectLoggedInState = state => !!state.user.user;
export const {setUserState} = slice.actions;

export default slice.reducer;