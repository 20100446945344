import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useDispatch, useSelector} from "react-redux";
import {selectNewCategoryDialogOpen, openCloseNewCategoryDialog} from '../ListManager/ListManagerSlice';


export default (props) => {
    const isOpen = useSelector(selectNewCategoryDialogOpen);
    const [input, setInput] = useState(false);

    const dispatch = useDispatch();

    const handleChange = e => {
        const {value} = e.target;
        setInput(value);
    }

    const handleClose = () => {
        dispatch(openCloseNewCategoryDialog({code: false}))
    };

    const handleSubmit = () => {
        props.addNewItemToList(input)
        dispatch(openCloseNewCategoryDialog({code: false}))
    }

    return (
        <div>
            <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Stofna nýjan flokk</DialogTitle>
                <DialogContent>
                    <TextField onChange={handleChange} autoFocus margin="dense" id="name" label="Nafn á flokk" type="text" fullWidth/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Hætta við
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Stofna
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
