import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import ListItem from "./ListItem";

const useStyles = makeStyles({
    title: {
        flexGrow: 1,
    }
});

export default (props) => {
    const classes = useStyles();

    console.log("props.lists",props.lists);
    return (
        <>
            <h2 className={classes.title}>{props.title}</h2>
            <Grid container spacing={1}>
                {props.lists.map(list =>
                    <Grid style={{marginBottom: "10px"}} item xs={6} md={4} lg={3}>
                        <ListItem key={list.code} list={list}/>
                    </Grid>
                )}
            </Grid>
        </>
    );
}