import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import {useHistory} from "react-router-dom";
import Popover from "@material-ui/core/Popover";
import Box from "@material-ui/core/Box";
import SelectListLength from "../../OtherComponents/NavBar/SettingsItems/SelectListLength";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import {useDispatch, useSelector} from "react-redux";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {initSettings, openSettings, selectRandomize, setRandomize, setWordListLength} from "../../OtherComponents/NavBar/SettingsDrawerSlice";
import LinesEllipsis from 'react-lines-ellipsis';
import ReactMarkdown from "react-markdown";
import Utils from "../../../utils/web-utils";
import SettingsSwitchItem from "../../OtherComponents/NavBar/SettingsItems/SettingsSwitch";
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import CameraAltIcon from '@material-ui/icons/CameraAlt';

const useStyles = makeStyles({
    zoom: {
        minWidth: "66vw",
        minHeight: "44vw",
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        border: "0",

        ['@media (min-width:630px)']: { // eslint-disable-line no-useless-computed-key
            minWidth: "55vw",
            minHeight: "37vw",
        },
        ['@media (min-width:941px)']: { // eslint-disable-line no-useless-computed-key
            minWidth: "38vw",
            minHeight: "25vw",

        },
        ['@media (min-width:1280px)']: { // eslint-disable-line no-useless-computed-key
            minWidth: "30vw",
            minHeight: "20vw",

        },
        ['@media (min-width:1800px)']: { // eslint-disable-line no-useless-computed-key
            minWidth: "27vw",
            minHeight: "18vw",
        },

    },
    root: {
        // contentVisibility: "auto",
        marginRight: "4px",
        minWidth: "10vw",
        minHeight: 120,
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        ['@media (min-width:630px)']: { // eslint-disable-line no-useless-computed-key
            marginRight: "10px",
            minWidth: "10vw",
            minHeight: 160,
        },

        height: "10vw",
        border: "0"
    },
    shortDescription: {
        ['@media (max-width:530px)']: { // eslint-disable-line no-useless-computed-key
            maxHeight: "2.4em",
        },
    },
    description: {
        '& a': {
            color: "white"
        },
        color: "white",
        fontWeight: "400",
        overflow: "hidden",
        fontSize: "14px",
        margin: "0 0 0 0",
        whiteSpace: "pre-line",
        ['@media (min-width:450px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "16px",
        },
        ['@media (min-width:630px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "18px",
        },
        ['@media (min-width:1800px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "22px",
        },
    },
    name: {
        maxHeight: "1.3em",
        color: "white",
        fontWeight: "400",
        margin: "0 0 6px 0",
        fontSize: "18px",
        overflow: "hidden",
        ['@media (min-width:450px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "20px",
        },
        ['@media (min-width:630px)']: { // eslint-disable-line no-useless-computed-key
            maxHeight: "none",
            fontSize: "26px",
        },
        ['@media (min-width:1800px)']: { // eslint-disable-line no-useless-computed-key
            maxHeight: "none",
            fontSize: "32px",
        },
    },
    whiteText: {
        marginRight: 12,
        color: "white"
    },
    cardContent: {
        flexGrow: 1,
        ['@media (min-width:630px)']: { // eslint-disable-line no-useless-computed-key
            padding: "16px 16px 0 16px",
        },
        padding: "8px 10px 0 10px",
    },
    cardActions: {
        margin: 0,
        backgroundColor: 'rgba(16,16,16,0.7)',
    },
    mainList: {
        flexGrow: 1
    },
    listItem: {
        color: "white",
        paddingBottom: "15px"
    },
    listItemSecondary: {
        color: "white",
        paddingBottom: "15px",
        ['@media (max-width:630px)']: { // eslint-disable-line no-useless-computed-key
            display: "none"
        }
    },
    expandMoreIcon: {
        fontSize: 40,
        color: "white",
    },
    imageIcon: {
        color: "rgb(255,255,255,0.5)",
        position: "absolute",
        bottom: "8px",
        right: "calc(4% + 35px)",
        ['@media (max-width:630px)']: { // eslint-disable-line no-useless-computed-key
            right: "calc(4% + 25px)",
            bottom: "10px",
            fontSize: 15
        }
    },
    soundIcon: {
        color: "rgb(255,255,255,0.5)",
        position: "absolute",
        bottom: "10px",
        right: "6%",
        ['@media (max-width:630px)']: { // eslint-disable-line no-useless-computed-key
            bottom: "10px",
            fontSize: 15
        }
    }
});


export default (props) => {
    const [divStyle, setDivStyle] = useState({});
    const [zoomDivStyle, setZoomDivStyle] = useState({});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const randomize = useSelector(selectRandomize);

    const anchorRef = useRef(null);
    const dispatch = useDispatch();

    const history = useHistory();
    const classes = useStyles();

    const handleExpandMoreClick = (event) => {
        event.stopPropagation();
        dispatch(initSettings({newState: props.list.code, settings: props.list.settings}));
        Utils.sendGAEvent("FrontPage", "ExpandMore", props.list.code)
        setAnchorEl(anchorRef.current);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        //TODO this random is only for mockup
        let colors = ["#1abc9c", "#3498db", "#9b59b6", "#16a085", "#27ae60", "#2980b9", "#8e44ad", "#f39c12", "#e67e22", "#e74c3c", "#d35400", "#1abc9c", "#9b59b6"];
        let rand = Math.floor(Math.random() * 10)
        const backgroundCount = 14;
        let rand2 = Math.floor(Math.random() * backgroundCount) + 1

        if (props.list.background) {
            let split = props.list.background.split(',');
            let number = split[1] % colors.length;
            setDivStyle({
                backgroundColor: colors[number],
                WebkitMaskSize: "160%",
                WebkitMaskImage: `url(/svg/paper/background${(split[0] % backgroundCount) + 1}.svg)`,
                maskImage: `url(/svg/paper/background${(split[0] % backgroundCount) + 1}.svg)`,
            })

            setZoomDivStyle({
                backgroundColor: colors[number],
                marginRight: 0,
                WebkitMaskSize: "200%",
                WebkitMaskImage: `url(/svg/paper/background${(split[0] % backgroundCount) + 1}.svg)`,
                maskImage: `url(/svg/paper/background${(split[0] % backgroundCount) + 1}.svg)`,
            })
        } else {
            setDivStyle({
                backgroundColor: colors[rand],
                WebkitMaskSize: "160%",
                WebkitMaskImage: `url(/svg/paper/background${rand2}.svg)`,
                maskImage: `url(/svg/paper/background${rand2}.svg)`,
            })

            setZoomDivStyle({
                backgroundColor: colors[rand],
                marginRight: 0,
                WebkitMaskSize: "200%",
                WebkitMaskImage: `url(/svg/paper/background${rand2}.svg)`,
                maskImage: `url(/svg/paper/background${rand2}.svg)`,
            })
        }

        window.addEventListener('scroll', () => handleClose());
        return () => window.removeEventListener('scroll', () => handleClose());
    }, []); // eslint-disable-line

    function allSettingsClick() {
        Utils.sendGAEvent("FrontPage", "OpenSettings", props.list.code)
        dispatch(initSettings({newState: props.list.code, settings: props.list.settings}));
        dispatch(openSettings());
    }

    function listLengthChange(e) {
        Utils.sendGAEvent("FrontPage", "listLengthChange", props.list.code, e.target.value)
        return dispatch(setWordListLength({newState: e.target.value}));
    }

    function randomizeListToggle() {
        Utils.sendGAEvent("FrontPage", "randomizeListToggle", props.list.code, !randomize ? 1 : 0)
        return dispatch(setRandomize({newState: !randomize}));
    }

    return (
        <>
            <Card ref={anchorRef} style={divStyle} onClick={() => history.push("/listi/" + props.list.code)} className={classes.root} variant="outlined">
                <CardContent className={classes.cardContent}>
                    {(props.list.type === "WordsDescAndPictures" || props.list.type === "WordsAndPictures") &&
                    <CameraAltIcon className={classes.imageIcon}/>
                    }
                    {props.list.listenToWord &&
                    <RecordVoiceOverIcon className={classes.soundIcon}/>
                    }
                    <p className={classes.name}>
                        {props.list.name}
                    </p>
                    {props.list.description &&
                    <LinesEllipsis
                        text={props.list.description}
                        maxLine={2}
                        ellipsis=""
                        className={classes.description + " " + classes.shortDescription}
                    />
                    }
                </CardContent>
                <Box display="flex" justifyContent="center" m={0} p={0}>
                    <ExpandMoreIcon onClick={handleExpandMoreClick} className={classes.expandMoreIcon}/>
                </Box>
            </Card>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                disableScrollLock={true}
            >
                <Card style={zoomDivStyle} className={classes.zoom} variant="outlined">
                    <CardContent className={classes.cardContent}>
                        <p className={`${classes.name}`}>
                            {props.list.name}
                        </p>
                        {props.list.description &&
                        <ReactMarkdown source={props.list.description} className={classes.description}/>
                        }
                    </CardContent>
                    <List className={classes.mainList}>
                        <ListItem className={classes.listItem}>
                            <SelectListLength style={{color: "white"}} listLengthChange={listLengthChange}/>
                        </ListItem>
                        <ListItem className={classes.listItemSecondary}>
                            <SettingsSwitchItem text="Raða handahófskennt" tooltip="Orðalistinn raðast handahófskennt eða eftir orðtíðni" handleToggle={() => randomizeListToggle()} checked={randomize}/>
                        </ListItem>
                    </List>
                    <Box display="flex" justifyContent="space-between" m={1} p={0.5} className={classes.cardActions}>
                        <Button onClick={() => allSettingsClick()} className={classes.whiteText} size="large">Allar Stillingar</Button>
                        <Button onClick={() => history.push("/listi/" + props.list.code)} className={classes.whiteText} size="large">Lesa lista</Button>
                    </Box>
                </Card>
            </Popover>
        </>
    );
}