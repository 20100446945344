import React from "react";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import {selectFullscreen} from "../../../utils/WithAndHeightUpdater/WithAndHeightSlice";
import Fab from "@material-ui/core/Fab";
import utils from "../../../utils/web-utils";


const useStyles = makeStyles(() => ({
    icon: {
        fontSize: "30px"
    },
}));


export default () => {
    const classes = useStyles();
    const fullscreen = useSelector(selectFullscreen);

    function handleFullscreen(e) {
        e.stopPropagation();

        if (fullscreen) {
            goOutFullScreen()
            return
        }

        const target = document.getElementById('mainWindow');
        try {
            if (target.requestFullscreen) {
                target.requestFullscreen();
            } else if (e.target.mozRequestFullScreen) {
                target.mozRequestFullScreen();
            } else if (e.target.webkitRequestFullscreen) {
                target.webkitRequestFullscreen();
            } else if (e.target.msRequestFullscreen) {
                target.msRequestFullscreen();
            }
        } catch (e) {
            console.log(e);
        }
    }

    function goOutFullScreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    }

    return (
        <>
            {!fullscreen &&
            <Fab color="primary" {...utils.stopEventPropigation} onClick={(e) => handleFullscreen(e)} aria-label="add">
                <FullscreenIcon className={classes.icon}/>
            </Fab>
            }
        </>
    );
}
