import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 250,
        fontSize: theme.typography.pxToRem(14),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

export default (props) => {
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };


    return (
        <>
            <HtmlTooltip
                PopperProps={{disablePortal: true}}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                onClose={handleTooltipClose}
                open={open}
                title={props.tooltip}
            >
                <div/>
            </HtmlTooltip>
            <ClickAwayListener onClickAway={handleTooltipClose}>


                <svg onClick={handleTooltipOpen} height="20" width="25">
                    <circle cx="12" cy="11" r="7" fill="black"/>
                    <text style={{fontFamily: "Georgia", fontSize: "12px", fontWeight: 900}} x="10" y="15" fill="white">i</text>
                </svg>

            </ClickAwayListener>
        </>
    );
}