import {configureStore} from '@reduxjs/toolkit';
import navBarSlice from '../components/OtherComponents/NavBar/NavBarSlice';
import settingsDrawerSlice from '../components/OtherComponents/NavBar/SettingsDrawerSlice';
import toastSlice from '../components/OtherComponents/Toast/ToastSlice';
import listManagerSlice from '../components/AdminSpace/ListManager/ListManagerSlice';
import frontPageSlice from '../components/PublicSpace/FrontPage/FrontPageSlice';
import loginModalSlice from '../components/OtherComponents/LoginModal/LoginModalSlice';
import userSlice from '../components/UserSlice';
import pathAndParams from '../utils/PathAndParamUpdater/PathAndParamsSlice';
import widthAndHeight from '../utils/WithAndHeightUpdater/WithAndHeightSlice';
import confirmCancelSlice from "../components/OtherComponents/ConfirmCancelDialog/ConfirmCancelSlice";
import WordListViewerSlice from "../components/PublicSpace/WordListViewer/WordListViewerSlice";
import soundPlayerSlice from "../components/OtherComponents/SoundPlayer/SoundPlayerSlice";

export default configureStore({
    reducer: {
        wordListViewer: WordListViewerSlice,
        menu: navBarSlice,
        detailsDialog: listManagerSlice,
        frontPage: frontPageSlice,
        loginModal: loginModalSlice,
        user: userSlice,
        soundPlayer: soundPlayerSlice,
        toast: toastSlice,
        widthAndHeight: widthAndHeight,
        pathAndParams: pathAndParams,
        settings: settingsDrawerSlice,
        confirmCancelDialog: confirmCancelSlice,
    },
});
