import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useDispatch, useSelector} from "react-redux";
import {selectOpenState, selectMessage, selectCancelText, selectConfirmText, selectTitle, setDialogClosed, selectConfirmPayload} from "./ConfirmCancelSlice";


export default function AlertDialogSlide(props) {
    const dispatch = useDispatch();
    const open = useSelector(selectOpenState);
    const message = useSelector(selectMessage);
    const title = useSelector(selectTitle);
    const cancelText = useSelector(selectCancelText);
    const confirmText = useSelector(selectConfirmText);
    const codeToDelete = useSelector(selectConfirmPayload);

    const handleConfirm = () => {
        props.confirmFunction(codeToDelete)
        dispatch(setDialogClosed());
    };

    const handleCancel = () => {
        dispatch(setDialogClosed());
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="prim   ary">
                        {cancelText}
                    </Button>
                    <Button onClick={handleConfirm} color="primary" autoFocus>
                        {confirmText}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
