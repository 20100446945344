import {createSlice} from '@reduxjs/toolkit';

const SETTINGS_VERSION = "2";

let FONT_FAMILY_DEFAULT = ["Lucida Grande", '-apple-system', 'BlinkMacSystemFont', '"Helvetica Neue"', 'Arial', 'sans-serif']


function getDefaultValueForSetting(action, setting, defaultSetting) {
    return action.payload.settings && typeof action.payload.settings.randomize !== "undefined" ? action.payload.settings[setting] : defaultSetting;
}

export const slice = createSlice({
    name: 'setting',
    initialState: {
        settingsOpen: false,
        listCode: "",
        randomize: false,
        allCaps: false,
        emptyBetween: false,
        wordListLength: 100,
        backgroundColor: false,
        listenToWord: true,
        fontFamily: FONT_FAMILY_DEFAULT
    },
    reducers: {
        initSettings(state, action) {
            const randomizeDefault = getDefaultValueForSetting(action, "randomize", true);
            state.listCode = action.payload.newState;
            state.wordListLength = getFromLocalStorage(state, 'wordListLength', false, 100);
            state.randomize = getFromLocalStorage(state, 'randomize', false,  randomizeDefault);
            state.allCaps = getFromLocalStorage(state, 'allCaps', true, false);
            state.emptyBetween = getFromLocalStorage(state, 'emptyBetween', true, false);
            state.backgroundColor = getFromLocalStorage(state, 'backgroundColor', true, false);
            state.listenToWord = getFromLocalStorage(state, 'listenToWord', true, true);
            state.fontFamily = getFromLocalStorage(state, 'fontFamily', true, FONT_FAMILY_DEFAULT);
        },
        openSettings(state) {
            state.settingsOpen = true;
        },
        closeSettings(state) {
            state.settingsOpen = false;
        },
        setWordListLength(state, action) {
            return saveAndToLocalStorage(state, action.payload.newState, "wordListLength", false);
        },
        setRandomize(state, action) {
            return saveAndToLocalStorage(state, action.payload.newState, "randomize", false);
        },
        setAllCaps(state, action) {
            return saveAndToLocalStorage(state, action.payload.newState, "allCaps", true);
        },
        setListenToWord(state, action) {
            return saveAndToLocalStorage(state, action.payload.newState, "listenToWord", true);
        },
        setEmptyBetween(state, action) {
            return saveAndToLocalStorage(state, action.payload.newState, "emptyBetween", true);
        },
        setBackgroundColor(state, action) {
            return saveAndToLocalStorage(state, action.payload.newState, "backgroundColor", true);
        },
        setFontFamily(state, action) {
            let fontFamily = FONT_FAMILY_DEFAULT;
            if (action.payload.newState) {
                fontFamily = "Open Dyslexic"
            }
            return saveAndToLocalStorage(state, fontFamily, "fontFamily", true);
        },
    },
});

function getFromLocalStorage(state, key, globalState, defaultValue) {
    key = globalState ? (key + SETTINGS_VERSION) : (key + state.listCode + SETTINGS_VERSION)
    try {
        return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : defaultValue;
    } catch (e) {
        return defaultValue
    }
}

function saveAndToLocalStorage(state, newState, key, globalState) {
    state[key] = newState;
    key = globalState ? (key + SETTINGS_VERSION) : (key + state.listCode + SETTINGS_VERSION)
    localStorage.setItem(key, JSON.stringify(newState));
}

export const selectSettingsState = state => state.settings.settingsOpen;
export const selectRandomize = state => state.settings.randomize;
export const selectAllCaps = state => state.settings.allCaps;
export const selectEmptyBetween = state => state.settings.emptyBetween;
export const selectWordListLength = state => state.settings.wordListLength;
export const selectBackgroundColor = state => state.settings.backgroundColor;
export const selectFontFamily = state => state.settings.fontFamily;
export const selectListenToWord = state => state.settings.listenToWord;
export const {initSettings, openSettings, closeSettings, setRandomize, setAllCaps, setListenToWord, setEmptyBetween, setWordListLength, setBackgroundColor, setFontFamily} = slice.actions;

export default slice.reducer;