import React from 'react';
import MaterialLink from "@material-ui/core/Link";
// import {Link} from 'react-router-dom';

export default (props) => {
    let linkToUse;

    // if (props.isExported) {
        linkToUse = <MaterialLink href={props.to} className={props.className}> {props.children}</MaterialLink>
    // } else {
    //     linkToUse = <Link to={props.to} className={props.className} color="inherit"> {props.children} </Link>
    // }

    return (
        <>
            {linkToUse}
        </>
    );
}
