import React, {forwardRef} from 'react';
import MaterialTable from "material-table";
import tableIconConfig from "../../../utils/tableIconConfig";
import ChipList from "../../OtherComponents/ChipList/ChipList";
import {Link} from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import {openDialogWithCode} from "./ListManagerSlice";
import {useDispatch} from "react-redux";
import Button from "@material-ui/core/Button";
import {Delete} from "@material-ui/icons";
import {setDialogOpen} from "../../OtherComponents/ConfirmCancelDialog/ConfirmCancelSlice";


export default (props) => {
    const dispatch = useDispatch();

    const handleClickOpen = (code) => {
        dispatch(openDialogWithCode({code: code}))
    };
    const handleClickDelete = (item) => {
        dispatch(setDialogOpen({
            message: "Ertu viss um að þú viljir eyða listanum " + item.name,
            title: "Eyða lista",
            cancelText: "Hætta við",
            confirmText: "Eyða lista",
            confirmPayload: item.code,
        }));
    };

    return (
        <div style={{maxWidth: "100%"}}>
            <MaterialTable
                title="Listar"
                icons={tableIconConfig}
                options={{
                    pageSizeOptions: [25, 50, 100, 200],
                    emptyRowsWhenPaging: false,
                    pageSize: 25
                }}
                localization={{
                    header: {
                        actions: ""
                    }
                }}
                actions={[
                    {
                        icon: forwardRef((props, ref) => <EditIcon  {...props} ref={ref}/>),
                        iconProps: {style: {fontSize: "14px", color: "green"}},
                        tooltip: "Breyta lista",
                        onClick: (event, rowData) => handleClickOpen(rowData.code)
                    }
                ]}
                columns={[
                    {title: "Nafn", field: "name"},
                    {title: "Lýsing", field: "description"},
                    {
                        title: "Url",
                        field: "code",
                        render: rowData => <Link to={'/listi/' + rowData.code}>{'/listi/' + rowData.code}</Link>
                    },
                    {
                        title: 'Flokkar',
                        field: 'category',
                        render: rowData => <ChipList list={rowData.category}/>
                    },
                    {
                        title: "Fjöldi orða",
                        field: "list",
                        render: rowData => <p> {rowData.wordCount} orð</p>
                    }
                ]}
                detailPanel={
                    rowData => {
                        return (
                            <div style={{marginLeft: "30px"}}>
                                <Button
                                    style={{margin: 20}}
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<Delete/>}
                                    onClick={() => handleClickDelete(rowData)}
                                >
                                    Eyða Listanum "{rowData.code}"
                                </Button>
                            </div>
                        )
                    }}
                data={props.lists}
            />
        </div>
    );
}
