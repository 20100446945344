const hostname = typeof window !== "undefined" && window && window.location && window.location.hostname;

const wrapper = {
    analyticsConfig: () => {
        if (hostname === "100ord.is" || hostname === "www.100ord.is") {
            return 'UA-9415153-5'
        } else {
            return 'UA-9415153-9'
        }
    },

    firebaseConfig: () => {
        if (hostname === "100ord.is" || hostname === "www.100ord.is" || hostname === "prod-100-ord.firebaseapp.com") {
            return {
                apiKey: "AIzaSyCpFOh9ylRDW8ug1Bs_R94rxQpomqBYOYY",
                authDomain: "prod-100-ord.firebaseapp.com",
                databaseURL: "https://prod-100-ord.firebaseio.com",
                projectId: "prod-100-ord",
                storageBucket: "prod-100-ord.appspot.com",
                messagingSenderId: "819813321956",
                appId: "1:819813321956:web:26ea428dbe4c3e1964f0f1"
            }
        } else {
            return {
                apiKey: "AIzaSyCL0aXUB-Ml2PzrzDRZ_mG3eK6MWlLCCWY",
                authDomain: "words-stage.firebaseapp.com",
                databaseURL: "https://words-stage.firebaseio.com",
                projectId: "words-stage",
                storageBucket: "words-stage.appspot.com",
                messagingSenderId: "354941585975",
                appId: "1:354941585975:web:0738a5ee1fa83b90d48db2"
            };
        }
    }
}

export default wrapper
