import React from "react";
import ReplayIcon from '@material-ui/icons/Replay';
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectBackgroundColor} from "../../OtherComponents/NavBar/SettingsDrawerSlice";
import util from "../../../utils/web-utils";
import Utils from "../../../utils/web-utils";

const useStyles = makeStyles(() => ({

    icon: {
        padding: 10,
        fontSize: "5em"
    }
}));

export default (props) => {
    const classes = useStyles();
    const history = useHistory();
    const backgroundColor = useSelector(selectBackgroundColor);
    let iconColor = backgroundColor ? {color: "white"} : {};

    function goBackClick() {
        Utils.sendGAEvent("WordListViewer", "GoBackFinished", props.code)
        history.push("/");
    }

    return (
        <>
            <Grid  direction="row" justify="center" alignItems="center" container spacing={3}>
                <Grid item xs={12}>
                    <h2>Þetta tók {(props.elapsedTime / 1000 > 60) ? (Math.floor(props.elapsedTime / 1000 / 60)) + " min " : ""}
                        {Math.floor(props.elapsedTime / 1000 % 60)} sek.</h2>
                </Grid>
                <Grid item xs={5}>
                    <IconButton style={iconColor} {...util.stopEventPropigation} onClick={() => goBackClick()} aria-label="icon">
                        <ArrowBackIosIcon className={classes.icon}/>
                    </IconButton>
                </Grid>
                <Grid item xs={5}>
                    <IconButton style={iconColor} {...util.stopEventPropigation} onClick={() => props.readAgain()} aria-label="icon">
                        <ReplayIcon {...util.stopEventPropigation} onClick={() => props.readAgain()} className={classes.icon}/>
                    </IconButton>
                </Grid>
            </Grid>
        </>
    );
}
