import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import apiWrapper from "../../../utils/apiWrapper";
import SimpleLoading from "../../OtherComponents/Loading/SimpleLoading";
import ListDetailsDialog from "../ListDetailsDialog/ListDetailsDialog";
import AddIcon from '@material-ui/icons/Add';
import Button from "@material-ui/core/Button";
import {openCreateListDialog} from "./ListManagerSlice";
import {useDispatch} from "react-redux";
import ListTable from "./ListTable";
import ConfirmCancelDialog from "../../OtherComponents/ConfirmCancelDialog/ConfirmCancelDialog";

const useStyles = makeStyles(() => ({
    title: {
        flexGrow: 1,
    },
}));

export default () => {
    const dispatch = useDispatch();

    const [fetchedLists, setFetchedLists] = useState([]);
    const [loading, setLoading] = useState(true);
    const [allCategories, setAllCategories] = useState([]);
    let theList;

    useEffect(() => {
        fetchData().catch(console.error);
    }, []);

    async function fetchData() {
        setLoading(true)
        let allLists = await apiWrapper.getAllLists();
        setFetchedLists(allLists)
        let categories = new Set(allLists.map(list => list.category).filter(Boolean).flat())
        categories = Array.from(categories).map(item => {
            return {title: item};
        })
        setLoading(false)
        setAllCategories(categories);
    }

    if (loading) {
        theList = <SimpleLoading/>
    } else {
        if (fetchedLists.length > 0) {
            theList = <ListTable lists={fetchedLists}/>
        } else {
            theList = <p>No lists to display</p>
        }
    }

    const handleClickOpen = () => {
        dispatch(openCreateListDialog())
    };

    const handleConfirmDelete = async (codeToDelete) => {
        await apiWrapper.deleteWordList(codeToDelete).catch(console.error);
        await fetchData().catch(console.error);
    }

    const classes = useStyles();
    return (
        <Container component="main" maxWidth="lg">
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <ConfirmCancelDialog confirmFunction={handleConfirmDelete}/>
                    <ListDetailsDialog reloadParent={fetchData} allCategories={allCategories}/>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.button}
                        startIcon={<AddIcon/>}
                        onClick={handleClickOpen}
                    >
                        Stofna lista
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    {theList}
                </Grid>
            </Grid>
        </Container>
    );
}
