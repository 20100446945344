import React, {useRef, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import * as EmailValidator from 'email-validator';

import {useDispatch, useSelector} from "react-redux";
import {selectOpenState, setLoginOpenState} from "./LoginModalSlice";

import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import apiWrapper from "../../../utils/apiWrapper";
import {setToastOpen} from "../Toast/ToastSlice";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(4, 0, 3, 0),
    },
    dialog: {
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(1),
        }
    },
    requestAccount: {
        textAlign: "right"
    },
    textField: {
        margin: theme.spacing(0, 5),
        width: "80%"
    }
}));

export default function AlertDialogSlide() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const usernameEl = useRef(null);
    const passwordEl = useRef(null);

    const dispatch = useDispatch();
    const isOpen = useSelector(selectOpenState);
    const classes = useStyles();

    const handlePasswordChange = (e) => {
        const {value} = e.target;
        setPassword(value);
    };

    const handleUsernameChange = (e) => {
        const {value} = e.target;
        setUsername(value);
    };

    const handleClose = () => {
        dispatch(setLoginOpenState({newState: false}))
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (username.length <= 4) {
            dispatch(setToastOpen({newState: true, message: "Tölvupóstfang er of stutt", severity: "error"}));
            usernameEl.current.focus();
            return
        }
        if (!EmailValidator.validate(username)) {
            dispatch(setToastOpen({newState: true, message: "Tölvupóstfang ekki rétt", severity: "error"}));
            usernameEl.current.focus();
            return
        }

        try {
            await apiWrapper.login(username, password);
            dispatch(setLoginOpenState({newState: false}))
            dispatch(setToastOpen({newState: true, message: "Innskráning tókst", severity: "success"}));
            setUsername("")
            setPassword("")
        } catch (e) {
            //TODO handle more types of errors
            dispatch(setToastOpen({newState: true, message: "Tölvupóstfang eða lykilorð var rangt. Vinsamlega reyndu aftur.", severity: "error"}));
            setPassword("")
            passwordEl.current.focus();
        }
    }

    return (
        <div>
            <Dialog
                classes={{paper: classes.dialog}}
                maxWidth={"xs"}
                open={isOpen}
                TransitionComponent={Transition}
                keepMounted
                scroll={"body"}
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Innskráning á mitt svæði"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Ef þig vantar aðgang getur þú sótt um að taka þátt í "Beta" prófunum.
                    </DialogContentText>
                    <form onSubmit={handleSubmit} className={classes.form} noValidate>
                        <TextField
                            inputRef={usernameEl}
                            className={classes.textField}
                            onChange={handleUsernameChange}
                            value={username}
                            required
                            fullWidth
                            id="email"
                            label="Tölvupóstfang"
                            name="Tölvupóstfang"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            inputRef={passwordEl}
                            className={classes.textField}
                            onChange={handlePasswordChange}
                            value={password}
                            required
                            fullWidth
                            id="password"
                            name="Lykilorð"
                            label="Lykilorð"
                            type="password"
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Innskrá
                        </Button>
                        <Grid container>
                            <Grid item xs={12} sm>
                                <Link href="#" variant="body2">
                                    {'Gleymt lykilorð?'}
                                </Link>
                            </Grid>
                            <Grid className={classes.requestAccount} item xs={12} sm>
                                <Link href="#" variant="body2">
                                    {'Sækja um aðgang'}
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
}
